// Import the functions you need from the Firebase SDK
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics"; // Optional if you plan to use analytics

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDMyeqdzYMUQMjDAHFpF7Qsn976Y08cPMc",
    authDomain: "commuterslink-d7239.firebaseapp.com",
    projectId: "commuterslink-d7239",
    storageBucket: "commuterslink-d7239.firebasestorage.app",
    messagingSenderId: "311452457062",
    appId: "1:311452457062:web:2d2f0dae7a7d38c35035a7",
    measurementId: "G-1018NZ5E57"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Optional: Initialize Analytics (if you are using Firebase Analytics)
const analytics = getAnalytics(app);

export { app, analytics }; // Export the Firebase app object to use in other files
