import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Container, Row, Modal } from 'react-bootstrap';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs';
import { Button } from "@mui/base";
import Stack from "@mui/material/Stack";
import { GoogleMap, Autocomplete, MarkerF, } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { API_URL } from "../../../constants";
import { Checkbox, FormControlLabel } from "@mui/material";
import Swal from "sweetalert2";
import { displayNotification } from "../../../helpers";


const ThreeMonthsFromNow = dayjs().add(3, 'month');
const DriverRegistration = () => {

  const navigate = useNavigate();
  const autocompleteRef = useRef(null);
  const userToken = useSelector((s) => s.login.userToken);
  const [addNewStart, setAddNewStart] = useState(false);
  const [addNewStartDropdown, setAddNewStartDropdown] = useState(true);
  const [addNewStartField, setAddNewStartField] = useState(true);
  const [addNewEnd, setAddNewEnd] = useState(false);
  const [addNewEndDropdown, setAddNewEndDropdown] = useState(true);
  const [addNewEndField, setAddNewEndField] = useState(true);
  const [daysSelected, setDaysSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidJazzCash, setIsValidJazzCash] = useState(true);
  const [isValidEasyPaisa, setIsValidEasyPaisa] = useState(true);
  const [isValidRaastID, setIsValidRaastID] = useState(true);

  const route = () => {
    navigate("/seatcostverification");
  };

  const handleCarBrandChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^a-zA-Z]/g, '');
    setSelectedCarBrand(value);
    setIsCarBrandValid(value !== '');
    if (value.length > 20) {
      value = value.slice(0, 20);
    }
    setSelectedCarBrand(value);

    setIsCarBrandValid(value.length === 20);
  };


  const handleDrivingLicenseMySelf = (e) => {
    let value = e.target.value;
    value = value.replace(/[^a-zA-Z0-9-]/g, '');
    setInputDrivingLicenseMySelf(value);
    setIsLicenseValid(value !== '');
    if (value.length > 20) {
      value = value.slice(0, 20);
    }
    setInputDrivingLicenseMySelf(value);

    setIsLicenseValid(value.length === 20);
  };


  const handleValidChange = (newDate) => {
    if (newDate) {
      const newDateObject = dayjs(newDate);
      setInputValidUptoMySelf(newDateObject);
    }
  };

  const shouldDisableDate = (date) => {
    return date.isBefore(ThreeMonthsFromNow);
  };
  const AddNewStart = () => {
    setAddNewStart(true);
    setAddNewStartDropdown(false);
    setAddNewStartField(false);
  };

  const AddNewEnd = () => {
    setAddNewEnd(true);
    setAddNewEndDropdown(false);
    setAddNewEndField(false);
  };

  const openPopup = () => {
    Swal.fire({

      html: `
        <div>
          <div class="container mt-5 pt-1 fw-bold fs-1 mb-5">
            <span><i class=" p-3 wallet-margin fa-solid text-success fa-wallet fs-1"></i>
              Why Process Payment through CommutersLink</span>
          </div>
          <div>
            <div class="container">
              <div class="row">
                <div class="col-md-8  col-lg-12 col-sm-8 mb-5">
                  <div class="bg-light text-left">
                    <ol class="p-4 text-justify">
                      <li class = "mb-2">
                      Commuterslink holds 15 days advance from the traveler and, upon confirming the journey, will transfer the daily ride cost to your wallet. Subsequently, you can receive these funds through the chosen preferred payment method at the time of registration. Your money is not only secure but also protected with end-to-end encryption.
                      </li>
                  
                   
                      <li class = "mb-2">
                      No bargaining involved. Commuterslink works out the per-day per-seat cost based upon a fixed rationalized formula. If the petrol prices go up or down, the same will be adjusted.
                      </li>
                 
                  
                    <li class = "mb-2"> No dispute on cost,payments,number of days the services were utilized etc.</li>
        
                   <li class = "mb-2">If you stop provision of car seat to a partner for a reason or other with 1 week, your payment is secure and you will still be able to get another match.</li> 
                            
                          <li class = "mb-2">
                          CommutersLink verifies all the data of its members and ensures your safety and security.                        </li>
                     
                      <li class = "mb-2">
                      By receiving money through CommutersLink you remain an active member and have access to other options to find travel buddies.                       </li>
                         
                           <li class = "mb-2">
                           Last but not the least, it is the most respectable way of receiving money as you do not have to ask your travel buddy to pay you cash.                                            </li>
                           <li class = "mb-2">You can add or update your preferred payment method from your profile page.</li>
                           </ol>

                           </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      `,
      width: '50%', 
      showCloseButton: true,
      showConfirmButton: false,
    });
  };

  // For Registration
  const [validated, setValidated] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCnicFront, setSelectedCnicFront] = useState(null);
  const [selectedCnicBack, setSelectedCnicBack] = useState(null);
  const [selectedCarPlate, setSelectedCarPlate] = useState(null);
  const [selectedLicenseFrontDriver, setSelectedLicenseFrontDriver] = useState(null);
  const [selectedLicenseBackDriver, setSelectedLicenseBackDriver] = useState(null);
  const [selectedCnicFrontDriver, setSelectedCnicFrontDriver] = useState(null);
  const [selectedCnicBackDriver, setSelectedCnicBackDriver] = useState(null);
  const [homeTimeSlots, setHomeTimeSlots] = useState([]);
  const [selectedHomeTime, setSelectedHomeTime] = useState("");
  const [selectedHomeTimeValue, setSelectedHomeTimeValue] = useState("");
  const [filteredOfficeTimeSlots, setFilteredOfficeTimeSlots] = useState([]);
  const [officeTimeSlots, setOfficeTimeSlots] = useState([]);
  const [selectedOfficeTime, setSelectedOfficeTime] = useState("");
  const [gender, setGender] = useState("");
  const [preferredGender, setPreferredGender] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const selectedDateFormat = selectedDate ? selectedDate.format('DD-MM-YYYY') : '';
  const [martialStatus, setMartialStatus] = useState("");
  const [education, setEducation] = useState("");
  const [cnic, setCnic] = useState('');
  const [isValidCnic, setIsValidCnic] = useState(true);
  const [isValidCnic1, setIsValidCnic1] = useState(true);
  const [cnicFront, setCnicFront] = useState("");
  const [cnicFrontExt, setCnicFrontExt] = useState("");
  const [cnicBack, setCnicBack] = useState("");
  const [cnicBackExt, setCnicBackExt] = useState("");
  const [picture, setPicture] = useState("");
  const [pictureExt, setPictureExt] = useState("");
  const [profession, setProfession] = useState('');
  const [isValidProfession, setIsValidProfession] = useState(true);
  const [isCarBrandValid, setIsCarBrandValid] = useState(true);
  const [isLicenseValid, setIsLicenseValid] = useState(true);

  // For Start Point
  const [startBounds, setStartBounds] = useState([]);
  const [autocompleteStartBounds, setAutocompleteStartBounds] = useState(null);
  const [locationStartString, setLocationStartString] = useState("");
  const [locationStartStringId, setLocationStartStringId] = useState("");
  const [locationStartStringField, setLocationStartStringField] = useState(locationStartString);
  const [dropdownStartdata, setDropDownStartData] = useState();
  const [provinceStartId, setProvinceStartId] = useState("");
  const [selectedStartProvinceCities, setSelectedStartProvinceCities] = useState([]);
  const [cityStart, setCityStart] = useState("");
  const [cityStartId, setCityStartId] = useState("");
  const [selectedStartCityArea, setSelectedStartCityArea] = useState([]);

  // For End Point
  const [endBounds, setEndBounds] = useState([]);
  const [autocompleteEndBounds, setAutocompleteEndBounds] = useState(null);
  const [locationEndString, setLocationEndString] = useState("");
  const [locationEndStringId, setLocationEndStringId] = useState("");
  const [locationEndStringField, setLocationEndStringField] = useState(locationEndString);
  const [dropdownEnddata, setDropDownEndData] = useState();
  const [provinceEndId, setProvinceEndId] = useState("");
  const [selectedEndProvinceCities, setSelectedEndProvinceCities] = useState([]);
  const [cityEnd, setCityEnd] = useState("");
  const [cityEndId, setCityEndId] = useState("");
  const [selectedEndCityArea, setSelectedEndCityArea] = useState([]);


  // For Start Point
  const [defaultStartCenter, setDefaultStartCenter] = useState({ lat: 30.3753, lng: 69.3451 });
  const [markerPositionStart, setMarkerPositionStart] = useState({ lat: null, lng: null });

  // For End Point
  const [defaultEndCenter, setDefaultEndCenter] = useState({ lat: 30.3753, lng: 69.3451 });
  const [markerPositionEnd, setMarkerPositionEnd] = useState({ lat: null, lng: null });

  // For Modals
  const [showStartModal, setShowStartModal] = useState(false);
  const [showEndModal, setShowEndModal] = useState(false);

  // For Driver's
  const [carBrand, setCarBrand] = useState([]);
  const [selectedCarBrand, setSelectedCarBrand] = useState("");
  const [selectedModelName, setSelectedModelName] = useState("");
  const [manYear, setManYear] = useState([]);
  const [selectedManYear, setSelectedManYear] = useState("");
  const [regYear, setRegYear] = useState([]);
  const [filteredRegYears, setFilteredRegYears] = useState([]);
  const [selectedRegYear, setSelectedRegYear] = useState("");
  const [carYearRanges, setCarYearRanges] = useState([]);
  const [selectedCarYearRanges, setSelectedCarYearRanges] = useState("");
  const [selectedRegNumber, setSelectedRegNumber] = useState("");
  const [selectedCarAC, setSelectedCarAC] = useState("");
  const [selectedCarImage, setSelectedCarImage] = useState("");
  const [selectedCarImageExt, setSelectedCarImageExt] = useState("");
  const [carCC, setCarCC] = useState([]);
  const [selectedCarCC, setSelectedCarCC] = useState("");
  const [selectedSeat, setSelectedSeat] = useState("");
  const [selectedSeatGender, setSelectedSeatGender] = useState("");
  const [selectedMidRoutePartner, setSelectedMidRoutePartner] = useState("");
  const [selectedOneRoutePartner, setSelectedOneRoutePartner] = useState("");
  const [selectedOption, setSelectedOption] = useState(''); // State to store the selected option
  const [inputBankAccount, setInputBankAccount] = useState("");
  const [inputEasyPaisa, setInputEasyPaisa] = useState("");
  const [inputJazzCash, setInputJazzCash] = useState("");
  const [inputRaastID, setInputRaastID] = useState("");

  // For Driver Type
  const [inputDriverType, setInputDriverType] = useState("");

  // I Drive Myself Fields
  const [inputDrivingLicenseMySelf, setInputDrivingLicenseMySelf] = useState("");
  const [inputValidUptoMySelf, setInputValidUptoMySelf] = useState(null);
  const inputValidUptoMySelfFormat = inputValidUptoMySelf ? inputValidUptoMySelf.format('DD-MM-YYYY') : '';
  const [inputPlaceIssueMySelf, setInputPlaceIssueMySelf] = useState("");

  // For Driver & Both Fields
  const [inputDriverName, setInputDriverName] = useState("");
  const [isValidDriverName, setIsValidDriverName] = useState(true);
  const [inputDriverCnicNumber, setInputDriverCnicNumber] = useState("");
  const [inputDriverCnicFront, setInputDriverCnicFront] = useState("");
  const [inputDriverCnicFrontExt, setInputDriverCnicFrontExt] = useState("");
  const [inputDriverCnicBack, setInputDriverCnicBack] = useState("");
  const [inputDriverCnicBackExt, setInputDriverCnicBackExt] = useState("");
  const [inputDriverLicenseNumber, setInputDriverLicenseNumber] = useState("");
  const [inputDriverValidUpto, setInputDriverValidUpto] = useState(null);
  const inputDriverValidUptoFormat = inputDriverValidUpto ? inputDriverValidUpto.format('DD-MM-YYYY') : '';
  const [isIBANValid, setIsIBANValid] = useState(true);

  // For License Fields
  const [selectedImageLicenseFront, setSelectedImageLicenseFront] = useState("");
  const [selectedImageLicenseFrontExt, setSelectedImageLicenseFrontExt] = useState("");
  const [selectedImageLicenseBack, setSelectedImageLicenseBack] = useState("");
  const [selectedImageLicenseBackExt, setSelectedImageLicenseBackExt] = useState("");

  // For Driver Form
  const [showDriverForm, setShowDriverForm] = useState(false);
  const [showmyself, setshowmyself] = useState(true);
  const [showmydriver, setshowmydriver] = useState(false);
  const [showboth, setshowboth] = useState(false);

  useEffect(() => {
    getdropdownStartdata();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate, showDriverForm]);

  useEffect(() => {

    if (provinceStartId) {
      const selectedStartProvince = dropdownStartdata?.countries[0]?.provinces.find(
        (province) => province.id == provinceStartId
      );
      setSelectedStartProvinceCities(
        selectedStartProvince ? selectedStartProvince.cities : []
      );
    }
  }, [provinceStartId]);


  useEffect(() => {

    if (provinceEndId) {
      const selectedEndProvince = dropdownEnddata?.countries[0]?.provinces.find(
        (province) => province.id == provinceEndId
      );
      setSelectedEndProvinceCities(
        selectedEndProvince ? selectedEndProvince.cities : []
      );
    }
  }, [provinceEndId]);

  useEffect(() => {

    if (cityStartId) {

      const filteredStartCities = dropdownStartdata.area.filter(
        (city) => city.parent_id == cityStartId
      );

      const citystring = selectedStartProvinceCities.filter(
        (city) => city.id == cityStartId
      );

      setLocationStartString(citystring[0].value);
      setSelectedStartCityArea(filteredStartCities ? filteredStartCities : []);
    }
  }, [cityStartId]);

  useEffect(() => {

    if (cityEndId) {

      const filteredEndCities = dropdownEnddata.area.filter(
        (city) => city.parent_id == cityEndId
      );

      const citystring = selectedEndProvinceCities.filter(
        (city) => city.id == cityEndId
      );

      setLocationEndString(citystring[0].value);
      setSelectedEndCityArea(filteredEndCities ? filteredEndCities : []);
    }
  }, [cityEndId]);



  useEffect(() => {
    // Function to fetch the geocoding data
    const getGeocodeStartData = async () => {
      try {

        if (locationStartString) {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(locationStartString)}&key=AIzaSyCrX4s2Y_jbtM-YZOmUwWK9m-WvlCu7EXA`
          );

          const data = await response.json(); // Parse the response as JSON

          if (data.status === 'OK' && data.results.length > 0) {
            const { lat, lng } = data.results[0].geometry.location;
            setDefaultStartCenter({ lat, lng });
            //setMarkerPositionStart({ lat, lng });
          } else {

          }
        }
      } catch (error) {

      }
    };
    getGeocodeStartData();
  }, [locationStartString, locationStartStringField]);

  useEffect(() => {
    // Function to fetch the geocoding data
    const getGeocodeEndData = async () => {
      try {
        if (locationEndString) {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(locationEndString)}&key=AIzaSyCrX4s2Y_jbtM-YZOmUwWK9m-WvlCu7EXA`
          );

          const data = await response.json(); // Parse the response as JSON
          if (data.status === 'OK' && data.results.length > 0) {
            const { lat, lng } = data.results[0].geometry.location;
            setDefaultEndCenter({ lat, lng });
            //setMarkerPositionEnd({ lat, lng });
          } else {

          }
        }
      } catch (error) {

      }
    };
    getGeocodeEndData();
  }, [locationEndString, locationEndStringField]);

  const getdropdownStartdata = async () => {
    const response = await fetch(
      `${API_URL}/api/v1/list/data`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );




    const jsonresponse = await response.json();
    setDropDownStartData(jsonresponse);
    setDropDownEndData(jsonresponse);
    setHomeTimeSlots(jsonresponse.time_slots);
    setOfficeTimeSlots(jsonresponse.time_slots);
    setCarBrand(jsonresponse.car_brand);
    setManYear(jsonresponse.car_reg_year);
    setRegYear(jsonresponse.car_reg_year);
    setCarYearRanges(jsonresponse.car_reg_year);
    setCarCC(jsonresponse.car_cc);

  };

  useEffect(() => {
    const fetchCityBounds = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${cityStart}&key=AIzaSyCrX4s2Y_jbtM-YZOmUwWK9m-WvlCu7EXA`
        );

        if (response.ok) {
          const data = await response.json();
          if (data.results.length > 0) {
            const cityBounds = data.results[0].geometry.bounds;

            setStartBounds(cityBounds);
          } else {
            //console.error('City not found');
          }
        } else {
          //console.error('Error fetching city data');
        }
      } catch (error) {
        //console.error('An error occurred:', error);
      }
    };

    if (cityStart) {
      fetchCityBounds();
    }
  }, [cityStart]);

  useEffect(() => {
    if (startBounds && startBounds.southwest && startBounds.northeast) {
      // Convert startBounds data into LatLngBounds object
      const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(startBounds.southwest.lat, startBounds.southwest.lng),
        new window.google.maps.LatLng(startBounds.northeast.lat, startBounds.northeast.lng)
      );

      setAutocompleteStartBounds(bounds);
    }
  }, [startBounds]);

  useEffect(() => {
    const fetchCityBounds = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${cityEnd}&key=AIzaSyCrX4s2Y_jbtM-YZOmUwWK9m-WvlCu7EXA`
        );

        if (response.ok) {
          const data = await response.json();
          if (data.results.length > 0) {
            const cityBounds = data.results[0].geometry.bounds;

            setEndBounds(cityBounds);
          } else {
            //console.error('City not found');
          }
        } else {
          //console.error('Error fetching city data');
        }
      } catch (error) {
        //console.error('An error occurred:', error);
      }
    };

    if (cityEnd) {
      fetchCityBounds();
    }
  }, [cityEnd]);

  useEffect(() => {
    if (endBounds && endBounds.southwest && endBounds.northeast) {
      // Convert startBounds data into LatLngBounds object
      const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(endBounds.southwest.lat, endBounds.southwest.lng),
        new window.google.maps.LatLng(endBounds.northeast.lat, endBounds.northeast.lng)
      );

      setAutocompleteEndBounds(bounds);
    }
  }, [endBounds]);

  useEffect(() => {
    // Filter registration years based on the selected manufacturing year.
    if (selectedManYear) {
      const filteredYears = regYear.filter((reg) => reg.car_year_ranges >= selectedManYear);
      setFilteredRegYears(filteredYears);
    } else {
      // If no manufacturing year is selected, show all registration years.
      setFilteredRegYears(regYear);
    }
  }, [selectedManYear]);

  useEffect(() => {
    // Filter registration years based on the selected manufacturing year.
    if (selectedHomeTimeValue) {
      const filteredYears = homeTimeSlots.filter((time) => time.time_string > selectedHomeTimeValue);
      setFilteredOfficeTimeSlots(filteredYears);
    } else {
      // If no manufacturing year is selected, show all registration years.
      setFilteredOfficeTimeSlots(homeTimeSlots);
    }
  }, [selectedHomeTimeValue]);

  const handleProvinceStartChange = (event) => {
    setCityStartId("");
    setLocationStartStringField("");
    setProvinceStartId(event.target.value);
  };


  const handleProfessionChange = (e) => {
    let newProfession = e.target.value;
    newProfession = newProfession.replace(/\s+/g, ' ').substring(0, 30);
    const professionPattern = /^[A-Za-z\s]+$/;

    // Check if the input matches the pattern.
    const isValid = professionPattern.test(newProfession);

    setIsValidProfession(isValid);
    setProfession(newProfession);
  };
  const preventNumbers = (e) => {
    const inputChar = String.fromCharCode(e.which);
    if (!/[A-Za-z\s]/.test(inputChar)) {
      e.preventDefault();
    }
  };

  const handleDriverNameChange = (e) => {
    let newName = e.target.value;
    newName = newName.replace(/\s+/g, ' ').substring(0, 25);
    const NamePattern = /^[A-Za-z\s]+$/;

    // Check if the input matches the pattern.
    const isValid = NamePattern.test(newName);

    setIsValidDriverName(isValid);
    setInputDriverName(newName);
  };
  const preventNumbers1 = (e) => {
    const inputChar = String.fromCharCode(e.which);
    if (!/[A-Za-z\s]/.test(inputChar)) {
      e.preventDefault();
    }
  };


  const handleProvinceEndChange = (event) => {
    setCityEndId("");
    setLocationEndStringField("");
    setProvinceEndId(event.target.value);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  const handleLocationStart = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedValue = selectedOption.value;
    const selectedId = selectedOption.getAttribute("data-id");
    setLocationStartString(selectedValue);
    setLocationStartStringId(selectedId);
    setAddNewStartField(false);
    handleShowStartModal();
  };

  const validateJazzCash = (inputJazzCash) => {
    // Regular expression pattern for validating Pakistan phone numbers (must start with "03" and have 11 digits)
    const phonePattern = /^03\d{9}$/;
    if (inputJazzCash === "" || phonePattern.test(inputJazzCash)) {
      setInputJazzCash(inputJazzCash);
      setIsValidJazzCash(true);
    } else {
      setInputJazzCash(inputJazzCash);
      setIsValidJazzCash(false);
    }
  };

  const handleLocationStartField = (e) => {
    setLocationStartStringField(e.target.value);
    setLocationStartString(e.target.value);
  };

  const validateEasyPaisa = (inputEasyPaisa) => {
    // Regular expression pattern for validating Pakistan phone numbers (must start with "03" and have 11 digits)
    const phonePattern = /^03\d{9}$/;
    if (inputEasyPaisa === "" || phonePattern.test(inputEasyPaisa)) {
      setInputEasyPaisa(inputEasyPaisa);
      setIsValidEasyPaisa(true);
    } else {
      setInputEasyPaisa(inputEasyPaisa);
      setIsValidEasyPaisa(false);
    }
  };


  const validateRaastID = (inputRaastID) => {
    // Regular expression pattern for validating Pakistan phone numbers (must start with "03" and have 11 digits)
    const phonePattern = /^03\d{9}$/;
    if (inputRaastID === "" || phonePattern.test(inputRaastID)) {
      setInputRaastID(inputRaastID);
      setIsValidRaastID(true);
    } else {
      setInputRaastID(inputRaastID);
      setIsValidRaastID(false);
    }
  };


  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value); // Update the selected option
  };

  const handlePlaceSelectStart = () => {
    const place = autocompleteRef.current.getPlace();

    // Check if place is defined and has address_components.
    if (place && place.address_components) {
      const isIslamabad =
        place.address_components.some((component) =>
          component.types.includes("locality") &&
          component.long_name.toLowerCase() === cityStart.toLowerCase()
        );

      if (isIslamabad) {
        // The selected place is in Islamabad.
        if (place.geometry && place.geometry.location) {
          setMarkerPositionStart({
            lat: null,
            lng: null,
          });

          setLocationStartStringField(place.formatted_address);
          setLocationStartString(place.formatted_address);
          handleShowStartModal();
        }
      } else if (!isIslamabad) {

        displayNotification("warning", `Please select a place in ${cityStart}.`);
      }
    } else {
      // Handle the case when place is not valid.
      //console.error('Invalid place object:', place);
    }
  };

  const handleLocationEnd = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedValue = selectedOption.value;
    const selectedId = selectedOption.getAttribute("data-id");
    setLocationEndString(selectedValue);
    setLocationEndStringId(selectedId);
    setAddNewEndField(false);
    handleShowEndModal();
  };

  const handleLocationEndField = (e) => {
    setLocationEndStringField(e.target.value);
    setLocationEndString(e.target.value);
  };

  const handlePlaceSelectEnd = () => {
    const place = autocompleteRef.current.getPlace();

    // Check if place is defined and has address_components.
    if (place && place.address_components) {
      const isIslamabad =
        place.address_components.some((component) =>
          component.types.includes("locality") &&
          component.long_name.toLowerCase() === cityEnd.toLowerCase()
        );

      if (isIslamabad) {
        // The selected place is in Islamabad.
        if (place.geometry && place.geometry.location) {
          setMarkerPositionEnd({
            lat: null,
            lng: null,
          });

          setLocationEndStringField(place.formatted_address);
          setLocationEndString(place.formatted_address);
          handleShowEndModal();
        }
      } else if (!isIslamabad) {
        
        displayNotification("warning", `Please select a place in ${cityEnd}.`);
      }
    } else {
      // Handle the case when place is not valid.
      //console.error('Invalid place object:', place);
    }
  };


  const handleMapClickStart = (event) => {

    setMarkerPositionStart({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const handleMapClickEnd = (event) => {

    setMarkerPositionEnd({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };


  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    // Check if the day is already selected or not
    if (daysSelected.includes(value)) {
      // Day is already selected, remove it from the array
      setDaysSelected((prevSelectedDays) =>
        prevSelectedDays.filter((day) => day !== value)
      );
    } else {
      // Day is not selected, add it to the array
      setDaysSelected((prevSelectedDays) => [...prevSelectedDays, value]);
    }
  };

  const handleDateChange = (newDate) => {
    if (newDate) {
      const newDateObject = dayjs(newDate);
      setSelectedDate(newDateObject);
    }
  };



  const handleValidDriverChange = (newDate) => {
    if (newDate) {
      const newDateObject = dayjs(newDate);
      setInputDriverValidUpto(newDateObject);
    }
  };

  function validateCnic(cnic) {
    // Regular expression pattern for validating Pakistani CNIC (12345-1234567-1)
    const cnicPattern = /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/;

    return cnicPattern.test(cnic);
  }

  const handleCnicChange = (event) => {
    const inputCnic = event.target.value.replace(/\D/g, '');

    if (cnic.length < 15 && cnic.length > 15) {
      setIsValidCnic(false);
    }
    if (inputCnic.length <= 13) {
      const formattedCnic = inputCnic.replace(
        /^(\d{5})(\d{7})(\d{1})$/,
        '$1-$2-$3'
      );
      setCnic(formattedCnic);
      setIsValidCnic(validateCnic(formattedCnic));
    }
  };

  function validateCnic1(inputDriverCnicNumber) {
    // Regular expression pattern for validating Pakistani CNIC (12345-1234567-1)
    const cnicPattern = /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/;

    return cnicPattern.test(inputDriverCnicNumber);
  }

  const handleCnicChange1 = (event) => {
    const inputCnic = event.target.value.replace(/\D/g, '');

    if (inputDriverCnicNumber.length < 15 && inputDriverCnicNumber.length > 15) {
      setIsValidCnic1(false);
    }
    if (inputCnic.length <= 13) {
      const formattedCnic = inputCnic.replace(
        /^(\d{5})(\d{7})(\d{1})$/,
        '$1-$2-$3'
      );
      setInputDriverCnicNumber(formattedCnic);
      setIsValidCnic1(validateCnic1(formattedCnic));
    }
  };


  const handleCnicFront = (e) => {
    const file = e.target.files[0];
    const maxSize = 5120000;
    if (file) {
      if (file.size <= maxSize) {
        const reader = new FileReader();
        reader.onload = () => {
          setCnicFront(reader.result.split(",")[1]);
          setCnicFrontExt(file.name.split('.').pop());
          setSelectedCnicFront(file);
        };
        reader.readAsDataURL(file);
      }
      else {

        displayNotification("warning", "Your file is exceeds 5MB");
        setTimeout(() => {
          e.target.value = null;
          setCnicFront(null);
          setCnicFrontExt(null);
        }, 100);
      }
    }
  };



  const handleCnicBack = (e) => {
    const file = e.target.files[0];
    const maxSize = 5120000;
    if (file) {
      if (file.size <= maxSize) {
        const reader = new FileReader();
        reader.onload = () => {
          setCnicBack(reader.result.split(",")[1]);
          setCnicBackExt(file.name.split('.').pop());
          setSelectedCnicBack(file);
        };
        reader.readAsDataURL(file);
      }
      else {

        displayNotification("warning", "Your file is exceeds 5MB");
        setTimeout(() => {
          e.target.value = null;
          setCnicBack(null);
          setCnicBackExt(null);
        }, 100);
      }
    }
  };

  const handleInputChange = (e) => {
    let value = e.target.value;

    // Remove any special characters and spaces
    value = value.replace(/[^a-zA-Z0-9]/g, '');

    // Enforce a maximum length of 24 characters
    if (value.length > 24) {
      value = value.slice(0, 24);
    }

    setInputBankAccount(value);

    // Check if the entered value is valid and reset the validation state
    setIsIBANValid(value.length === 24);
  };

  const handlePicture = (e) => {
    const file = e.target.files[0];
    const maxSize = 5120000;
    if (file) {
      if (file.size <= maxSize) {
        const reader = new FileReader();
        reader.onload = () => {
          setPicture(reader.result.split(",")[1]);
          setPictureExt(file.name.split('.').pop());
          setSelectedFile(file);
        };
        reader.readAsDataURL(file);
      }
      else {

        displayNotification("warning", "Your file is exceeds 5MB");
        setTimeout(() => {
          e.target.value = null;
          setPicture(null);
          setPictureExt(null);
        }, 100);
      }
    }
  };

  const handlePicturePreview = (e) => {
    setSelectedFile(null);
    setPicture("");
    setPictureExt("");
  };
  const handleCnicFrontPreview = (e) => {
    setSelectedCnicFront(null);
    setCnicFront("");
    setCnicFrontExt("");
  }

  const handleCnicBackPreview = (e) => {
    setSelectedCnicBack(null);
    setCnicBack("");
    setCnicBackExt("");
  }

  const handleLicenseFrontDriverPreview = (e) => {
    setSelectedLicenseFrontDriver(null);
    setSelectedImageLicenseFront("");
    setSelectedImageLicenseFrontExt("");
  }

  const handleLicenseBackDriverPreview = (e) => {
    setSelectedLicenseBackDriver(null);
    setSelectedImageLicenseBack("");
    setSelectedImageLicenseBackExt("");
  }


  const handleCarPlatePreview = (e) => {
    setSelectedCarPlate(null);
    setSelectedCarImage("");
    setSelectedCarImageExt("");
  }
  const handleCnicFrontDriverPreview = (e) => {
    setSelectedCnicFrontDriver(null);
    setInputDriverCnicFront("");
    setInputDriverCnicFrontExt("");
  }

  const handleCnicBackDriverPreview = (e) => {
    setSelectedCnicBackDriver(null);
    setInputDriverCnicBack("");
    setInputDriverCnicBackExt("");
  }

  const handleCnicFrontDriver = (e) => {
    const file = e.target.files[0];
    const maxSize = 5120000;
    if (file) {
      if (file.size <= maxSize) {
        const reader = new FileReader();
        reader.onload = () => {
          setInputDriverCnicFront(reader.result.split(",")[1]);
          setInputDriverCnicFrontExt(file.name.split('.').pop());
          setSelectedCnicFrontDriver(file);
        };
        reader.readAsDataURL(file);
      }
      else {

        displayNotification("warning", "Your file is exceeds 5MB");
        setTimeout(() => {
          e.target.value = null;
          setInputDriverCnicFront(null);
          setInputDriverCnicFrontExt(null);
        }, 100);
      }
    }
  };

  const handleCnicBackDriver = (e) => {
    const file = e.target.files[0];
    const maxSize = 5120000;
    if (file) {
      if (file.size <= maxSize) {
        const reader = new FileReader();
        reader.onload = () => {
          setInputDriverCnicBack(reader.result.split(",")[1]);
          setInputDriverCnicBackExt(file.name.split('.').pop());
          setSelectedCnicBackDriver(file);
        };
        reader.readAsDataURL(file);
      }
      else {

        displayNotification("warning", "Your file is exceeds 5MB");
        setTimeout(() => {
          e.target.value = null;
          setInputDriverCnicBack(null);
          setInputDriverCnicBackExt(null);
        }, 100);
      }
    }
  };

  const handleLicenseFrontDriver = (e) => {
    const file = e.target.files[0];
    const maxSize = 5120000;
    if (file) {

      if (file.size <= maxSize) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImageLicenseFront(reader.result.split(",")[1]);
          setSelectedImageLicenseFrontExt(file.name.split('.').pop());
          setSelectedLicenseFrontDriver(file);
        };
        reader.readAsDataURL(file);
      }
      else {

        displayNotification("warning", "Your file is exceeds 5MB");
        setTimeout(() => {
          e.target.value = null;
          setSelectedImageLicenseFront(null);
          setSelectedImageLicenseFrontExt(null);
        }, 100);
      }
    }
  };

  const handleLicenseBackDriver = (e) => {
    const file = e.target.files[0];
    const maxSize = 5120000;
    if (file) {

      if (file.size <= maxSize) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImageLicenseBackExt(reader.result.split(",")[1]);
          setSelectedImageLicenseBackExt(file.name.split('.').pop());
          setSelectedLicenseBackDriver(file);
        };
        reader.readAsDataURL(file);
      }
      else {

        displayNotification("warning", "Your file is exceeds 5MB");
        setTimeout(() => {
          e.target.value = null;
          setSelectedImageLicenseBackExt(null);
          setSelectedImageLicenseBackExt(null);
        }, 100);
      }
    }
  };

  const handleImageSelect = (e) => {
    const file = e.target.files[0];
    const maxSize = 5120000;
    if (file) {

      if (file.size <= maxSize) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedCarImage(reader.result.split(",")[1]);
          setSelectedCarImageExt(file.name.split('.').pop());
          setSelectedCarPlate(file);
        };
        reader.readAsDataURL(file);
      }
      else {

        displayNotification("warning", "Your file is exceeds 5MB");
        setTimeout(() => {
          e.target.value = null;
          setSelectedCarImage(null);
          setSelectedCarImageExt(null);
        }, 100);
      }
    }
  };

  useEffect(() => {
    handleDriverTypeForm();
  }, [inputDriverType]);

  const handleDriverTypeForm = () => {

    if (inputDriverType === "I Drive Myself") {
      setInputDriverName("");
      setInputDriverCnicNumber("");
      setInputDriverCnicFront("");
      setInputDriverCnicBack("");
      setInputDriverLicenseNumber("");
      setInputDriverValidUpto("");
      setSelectedImageLicenseFront("");
      setSelectedImageLicenseFrontExt("");
      setSelectedImageLicenseBack("");
      setSelectedImageLicenseBackExt("");
      setSelectedCnicFrontDriver(null);
      setSelectedCnicBackDriver(null);
      setSelectedLicenseFrontDriver(null);
      setSelectedLicenseBackDriver(null);
    }

    if (inputDriverType === "Driver") {
      setInputDriverName("");
      setInputDriverCnicNumber("");
      setInputDriverCnicFront("");
      setInputDriverCnicBack("");
      setInputDriverLicenseNumber("");
      setInputDriverValidUpto("");
      setInputDrivingLicenseMySelf("");
      setInputValidUptoMySelf("");
      setInputPlaceIssueMySelf("");
      setSelectedImageLicenseFront("");
      setSelectedImageLicenseFrontExt("");
      setSelectedImageLicenseBack("");
      setSelectedImageLicenseBackExt("");
      setSelectedCnicFrontDriver(null);
      setSelectedCnicBackDriver(null);
      setSelectedLicenseFrontDriver(null);
      setSelectedLicenseBackDriver(null);
    }

    if (inputDriverType === "Both") {
      setInputDriverName("");
      setInputDriverCnicNumber("");
      setInputDriverCnicFront("");
      setInputDriverCnicBack("");
      setInputDriverLicenseNumber("");
      setInputDriverValidUpto("");
      setInputDrivingLicenseMySelf("");
      setInputValidUptoMySelf("");
      setInputPlaceIssueMySelf("");
      setSelectedImageLicenseFront("");
      setSelectedImageLicenseFrontExt("");
      setSelectedImageLicenseBack("");
      setSelectedImageLicenseBackExt("");
      setSelectedCnicFrontDriver(null);
      setSelectedCnicBackDriver(null);
      setSelectedLicenseFrontDriver(null);
      setSelectedLicenseBackDriver(null);
    }

  };


  // For Modal Open & Close Functionality
  const isValidIBAN = (iban) => {
    // Regular expression for a 24-digit IBAN
    const ibanRegex = /^[A-Z0-9]{24}$/;
    return ibanRegex.test(iban);
  };

  const handleShowStartModal = () => {
    setShowStartModal(true);
  };

  const handleCloseStartModal = () => {
    if (markerPositionStart.lat === null && markerPositionStart.lng === null) {
      displayNotification("warning", "Please select a Starting Location from Map");
    }
    else {
      setShowStartModal(false);
    }
  };

  const handleShowEndModal = () => {
    setShowEndModal(true);
  };

  const handleCloseEndModal = () => {
    if (markerPositionEnd.lat === null && markerPositionEnd.lng === null) {
      displayNotification("warning", "Please select a Drop-off Location from Map");
    }
    else {
      setShowEndModal(false);
    }
  };

  const PersonalFormFields = [
    martialStatus,
    cnic,
    selectedDateFormat,
    gender,
    preferredGender,
    profession,
    education,
  ];

  const requiredFieldsLogin = [
    cityStartId, provinceStartId,
    locationStartString, markerPositionStart,
    cityEndId, provinceEndId,
    locationEndString, markerPositionEnd,
    selectedHomeTime, selectedOfficeTime, daysSelected,
    martialStatus, cnic, selectedDateFormat,
    gender, preferredGender, profession,
    education, cnicFrontExt, cnicFront,
    cnicBackExt, cnicBack, pictureExt, picture
  ];

  const requiredFieldsDriver = [
    selectedCarBrand, selectedCarCC,
    selectedModelName, selectedRegYear, selectedRegNumber,
    selectedManYear, selectedCarAC, selectedCarImage,
    selectedCarImageExt, selectedSeat, preferredGender,

  ];



  const handleLogin = async () => {

    await PersonalForm();
 
  };



  const handleDriver = async () => { 
   // navigate('/dashboard');
    await handleLogin();

  };

  const PersonalForm = async () => {
    try {
      const body = {
        marital_status: martialStatus,
        cnic: cnic,
        birth_year: selectedDateFormat,
        gender: gender,
        preferred_gender: preferredGender,
        profession: profession,
        education: education,
        interests: null,
        university_address: null,
        university_name: null,
        user_type: 299,
      };
      const response = await fetch(
        `${API_URL}/api/v1/registration/personal`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(body),
        }
      );



      const jsonresponse = await response.json();

      if (jsonresponse.statusCode == 200) {

        await LocationForm();
        //await ImagesFormCnicFront();
       // await ImagesFormCnicBack();
      //  await ImagesFormPicture();
        await DriverForm();

        // setIsLoading(false);
      } else if (jsonresponse.statusCode === 422) {

        const errors = jsonresponse.errors;
        for (const field of Object.keys(errors)) {
          Swal.fire({
            position: "top",
            // icon: "error",
            // text: `${jsonresponse.message}`,
            text: `${errors[field][0]}`,
            customClass: {
              confirmButton: "bg-success",
              // Apply custom CSS class to the OK button
            },
          });
        }
        setIsLoading(false);
      }
    } catch (error) {
      //console.log(error.message);
    }
  };

  const LocationForm = async () => {
    try {
      let nameArrayStart = [markerPositionStart.lat, markerPositionStart.lng];
      const myStringStart = nameArrayStart.toString();
      let nameArrayEnd = [markerPositionEnd.lat, markerPositionEnd.lng];
      const myStringEnd = nameArrayEnd.toString();
      const body = {
        option: 0,
        user_type: 299,
        university_name: null,
        university_address: null,
        veh_option: 1,
        start_point: {
          city_id: cityStartId,
          province_id: provinceStartId,
          area_id: locationStartStringId,
          area_google: {
            name: locationStartString,
            place_id: "ddChIJGQ_wq43t3zgRel4CwxgjgQs",
          },

          name: myStringStart,
          // "land_mark" : "Eagle Chowk"
        },
        end_point: {
          city_id: cityEndId,
          province_id: provinceEndId,
          area_id: locationEndStringId,
          area_google: {
            name: locationEndString,
            place_id: "ChIJGQ_wq43t3zgRel4CwxgjgQs"
          },
          name: myStringEnd,
          //  "land_mark" : "Clock Tower"
        },
        time: {
          start_time_id: selectedHomeTime,
          return_time_id: selectedOfficeTime,
        },
        days: daysSelected,
      }
      const response = await fetch(
        `${API_URL}/api/v1/registration/location`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(body),
        }
      );



      const jsonresponse = await response.json();

      if (jsonresponse.statusCode === 200) {
        //console.log("Location Form Response:", jsonresponse);
      }
      else if (jsonresponse.statusCode === 500) {
        displayNotification("error", `${jsonresponse.message}`);
      }
    } catch (error) {
      //console.log(error.message);
    }
  };

  const ImagesFormCnicFront = async () => {
    try {
      const body = {
        cnic_front_image_ext: cnicFrontExt,
        cnic_front_image: cnicFront,
      }
      const response = await fetch(
        `${API_URL}/api/v1/registration/store-images/cnic_front`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(body),
        }
      );



      const jsonresponse = await response.json();

      if (jsonresponse.statusCode === 200) {
        //console.log("Images Form Response Cnic Front:", jsonresponse);
      }
      else if (jsonresponse.statusCode === 500) {
        displayNotification("error", `${jsonresponse.message}`);
      }
    } catch (error) {
      //console.log(error.message);
    }
  };

  const ImagesFormCnicBack = async () => {
    try {
      const body = {
        cnic_back_image_ext: cnicBackExt,
        cnic_back_image: cnicBack,
      }
      const response = await fetch(
        `${API_URL}/api/v1/registration/store-images/cnic_back`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(body),
        }
      );



      const jsonresponse = await response.json();

      if (jsonresponse.statusCode === 200) {
        //console.log("Images Form Response Cnic Back:", jsonresponse);
      }
      else if (jsonresponse.statusCode === 500) {
        displayNotification("error", `${jsonresponse.message}`);
      }
    } catch (error) {
      //console.log(error.message);
    }
  };

  const ImagesFormPicture = async () => {
    try {
      const body = {
        picture_image_ext: pictureExt,
        picture: picture,
      }
      const response = await fetch(
        `${API_URL}/api/v1/registration/store-images/picture`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(body),
        }
      );


      const registrationSuccessful = () => {
      };

      const jsonresponse = await response.json();

      if (jsonresponse.statusCode === 200) {

        registrationSuccessful();
      }
      else if (jsonresponse.statusCode === 500) {
       
        displayNotification("error", `${jsonresponse.message}`);
      }
    } catch (error) {
      //console.log(error.message);
    }
  };

  const DriverForm = async () => {
    try {
      const body = {
        option: 1,
        car_brand: selectedCarBrand,
        car_cc: selectedCarCC,
        car_year_ranges: selectedManYear,
        car_model: selectedModelName,
        reg_year: selectedRegYear,
        reg_no: selectedRegNumber,
        manufac_year: selectedManYear,
        car_ac: selectedCarAC,
        car_image: selectedCarImage,
        car_image_ext: selectedCarImageExt,
        seats_available: selectedSeat,
        seats_for: preferredGender,
        driver_cnic_front_ext: cnicFrontExt,
        cnic_front_image: cnicFront,
        driver_cnic_back_ext: cnicBackExt,
        cnic_back_image: cnicBack,
        mid_route: "Yes",
        //one_side: selectedOneRoutePartner,
        drive_option: "Driver",
        license_no: inputDrivingLicenseMySelf,
        valid_upto: inputValidUptoMySelfFormat,
        place_issue: inputPlaceIssueMySelf,
        driver_name: inputDriverName,
        driver_cnic: inputDriverCnicNumber,
        driver_license_no: inputDriverLicenseNumber,
        driver_license_validity: inputDriverValidUptoFormat,
        license_front_image: selectedImageLicenseFront,
        license_back_image: selectedImageLicenseBack,
        license_front_image_ext: selectedImageLicenseFrontExt,
        license_back_image_ext: selectedImageLicenseBackExt
      }
      const response = await fetch(
        `${API_URL}/api/v1/registration/vehicle`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(body),
        }
      );



      const jsonresponse = await response.json();

      if (jsonresponse.statusCode === 200) {

        //await PaymentForm();
        navigate('/dashboard');
        setIsLoading(false);
      }
      else if (jsonresponse.statusCode === 100) {
        displayNotification("error", `${jsonresponse.message}`);
        setIsLoading(false);
      }
      else if (jsonresponse.statusCode === 500) {

        displayNotification("error", `${jsonresponse.message}`);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      //console.log(error.message);
    }
  };

  const PaymentForm = async () => {
    try {
      if (inputBankAccount || inputEasyPaisa || inputJazzCash || inputRaastID) {
        const body = {
          option: 1,
          drive_option: "Driver",
          bank_account_number: inputBankAccount,
          easy_paisa_number: inputEasyPaisa,
          jazz_cash_number: inputJazzCash,
          raast_number: inputRaastID
        }
        const response = await fetch(
          `${API_URL}/api/v1/registration/driver`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
              Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify(body),
          }
        );



        const jsonresponse = await response.json();

        if (jsonresponse.statusCode === 200) {

          displayNotification("success", "Driver Registration Form Submitted Successfully");
          route();
        }
        else if (jsonresponse.statusCode === 500) {
          displayNotification("error", `${jsonresponse.message}`);
        }
      }
      else {

        displayNotification("warning", "Please Enter Payment Details!");
      }
    } catch (error) {
      //console.log(error.message);
    }
  };

  return (
    <>

      {!showDriverForm && (
        <>
          <div className="main-bg">
            <div className="containter p-5 position-relative">
              <div className="area" >
                <ul className="circles">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div >
              <div className="row justify-content-center">
                <div className="col-md-6 bg-white mt-5 mb-5"  >

                  <div
                    className="row shadow  form-color-header"
                  // style={{ backgroundColor: '#1F5F5B' }}
                  >
                    <h1 className="text-center text-white py-4">
                      Registration Form
                    </h1>
                  </div>
                  <Form className="p-3 top-form" noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className="row mb-3 shadow shadow-sm ">
                      <div
                        className="col-md-12 px-2 py-3 form-color-field"
                      // style={{ backgroundColor: "#cddbd9" }}
                      >
                        <Form.Group as={Col} md="12" controlId="validationCustom11" className="mb-2">
                          <Form.Label className="fs-6 text-black">I am </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            className="text-secondary"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            required
                          >
                            <option value="" hidden> Gender </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </Form.Select>
                        </Form.Group>

                      </div>
                    </div>

                    <div className="row mb-3 shadow shadow-sm ">
                      <div
                        className="col-md-12 px-2 py-3 form-color-field"
                      // style={{ backgroundColor: "#cddbd9" }}
                      >
                        <h2 className="text-success mb-3 text-center">
                          STARTING POINT
                          <Tooltip title={<h6 className="px-2">{"You have the option to choose your starting or pickup location using Google Map, which becomes accessible once you have selected your province, city and area."}</h6>}>
                            <Link
                              // to='/notification'
                              className='mx-1 h-15px d-inline-block'
                              style={{ cursor: "pointer" }}
                            >
                              <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                            </Link>
                          </Tooltip>
                        </h2>



                        <Form.Group
                          as={Col}
                          md={cityStartId ? "12" : "12"}
                          controlId="validationCustom01"
                          className="mb-2"
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <Form.Label className="text-black fs-6">
                              Province
                            </Form.Label>
                            <p
                              className="colorplace text-danger"
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}

                            >

                            </p></div>
                          <Form.Select
                            aria-label="Default select example"
                            className="text-secondary"
                            value={provinceStartId}
                            onChange={handleProvinceStartChange}
                            required
                          >
                            <option value="" hidden>
                              Select a Province
                            </option>
                            {dropdownStartdata?.countries[0]?.provinces?.map(
                              (province) => (
                                <option key={province.id} value={province.id}>
                                  {province.value}
                                </option>
                              )
                            )}
                          </Form.Select>


                        </Form.Group>


                        <Form.Group as={Col} md={cityStartId ? "12" : "12"} controlId="validationCustom02" className="mb-2">
                          <Form.Label className="text-black fs-6">City</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            className="text-secondary"
                            value={cityStartId}
                            onChange={(e) => {
                              const selectedOption = e.target.options[e.target.selectedIndex];
                              const selectedValue = selectedOption.value;
                              const selectedId = selectedOption.getAttribute("data-id");

                              // Set the ID
                              setCityStartId(selectedValue);

                              // Set the value
                              setCityStart(selectedId);
                              setAddNewStartDropdown(true);
                              setAddNewStart(false);
                              setAddNewStartField(true);
                            }}
                            required
                          >
                            <option value="" hidden>
                              Select a City
                            </option>
                            {selectedStartProvinceCities?.map((province) => (
                              <option
                                key={province.id}
                                value={province.id}
                                data-id={province.value}
                              >
                                {province.value}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>

                        {cityStartId && (
                          <>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom56"
                            >
                              <Form.Label className="text-black">
                                Area
                              </Form.Label>
                              <Autocomplete
                                onLoad={(autocomplete) =>
                                  (autocompleteRef.current = autocomplete)
                                }
                                onPlaceChanged={handlePlaceSelectStart}
                                restrictions={{ country: "PK" }}
                                bounds={autocompleteStartBounds}
                                options={{ strictBounds: true }}
                              >
                                <Form.Control
                                  autoComplete="on"
                                  required
                                  type="text"
                                  value={locationStartStringField}
                                  onChange={handleLocationStartField}
                                  className="text-dark mt-1"
                                  placeholder="Enter your area"
                                  autocomplete="on"
                                  defaultValue=""
                                />
                              </Autocomplete>
                            </Form.Group>

   


                          </>
                        )}

                      </div>
                    </div>

                    <div className="row mb-3 shadow shadow-sm ">
                      <div
                        className="col-md-12 px-2 py-3 form-color-field"
                      // style={{ backgroundColor: "#cddbd9" }}
                      >
                        <h2 className="text-success mb-3 text-center">
                          DROP-OFF POINT
                          <Tooltip title={<h6 className="px-2">{"You have the option to choose your drop-off location using Google Map, which becomes accessible once you've selected your province, city and area."}</h6>}>
                            <Link
                              // to='/notification'
                              className='mx-1 h-15px d-inline-block'
                              style={{ cursor: "pointer" }}
                            >
                              <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                            </Link>
                          </Tooltip>
                        </h2>
                        <Form.Group
                          as={Col}
                          md={cityEndId ? "12" : "12"}
                          controlId="validationCustom57"
                          className="mb-2"
                        >

                          <div className="d-flex justify-content-between align-items-center">
                            <Form.Label className="text-black fs-6">
                              Province
                            </Form.Label>
                            <p
                              className="colorplace text-danger"
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            // onClick={AddNewStart}
                            >
             

                            </p></div>

                          <Form.Select
                            aria-label="Default select example"
                            className="text-secondary"
                            value={provinceEndId}
                            onChange={handleProvinceEndChange}
                            required
                          >
                            <option value="" hidden>
                              Select a Province
                            </option>
                            {dropdownEnddata?.countries[0]?.provinces?.map(
                              (province) => (
                                <option key={province.id} value={province.id}>
                                  {province.value}
                                </option>
                              )
                            )}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md={cityEndId ? "12" : "12"} controlId="validationCustom58" className="mb-2">
                          <Form.Label className="text-black fs-6">City</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            className="text-secondary"
                            value={cityEndId}
                            onChange={(e) => {
                              const selectedOption = e.target.options[e.target.selectedIndex];
                              const selectedValue = selectedOption.value;
                              const selectedId = selectedOption.getAttribute("data-id");

                              // Set the ID
                              setCityEndId(selectedValue);

                              // Set the value
                              setCityEnd(selectedId);
                              setAddNewEndDropdown(true);
                              setAddNewEnd(false);
                              setAddNewEndField(true);
                            }}
                            required
                          >
                            <option value="" hidden>
                              Select a City
                            </option>
                            {selectedEndProvinceCities?.map((province) => (
                              <option
                                key={province.id}
                                value={province.id}
                                data-id={province.value}
                              >
                                {province.value}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>



                        {cityEndId && (
                          <>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom08"
                            >
                              <Form.Label className="text-black">
                                Area
                              </Form.Label>
                              <Autocomplete
                                onLoad={(autocomplete) =>
                                  (autocompleteRef.current = autocomplete)
                                }
                                onPlaceChanged={handlePlaceSelectEnd}
                                restrictions={{ country: "PK" }}
                                bounds={autocompleteEndBounds}
                                options={{ strictBounds: true }}
                              >
                                <Form.Control
                                  autoComplete="on"
                                  required
                                  type="text"
                                  value={locationEndStringField}
                                  onChange={handleLocationEndField}
                                  className="text-dark mt-1"
                                  placeholder="Enter your area"
                                  autocomplete="on"
                                  defaultValue=""
                                />
                              </Autocomplete>
                            </Form.Group>
                           
                          </>
                        )}

                      </div>
                    </div>
                    <Modal show={showStartModal} onHide={handleCloseStartModal}>
                      <Modal.Header className="d-block" >
                        <Modal.Title>Select Starting Location</Modal.Title>
                        <Modal.Title className="text-danger fs-7">To get maximum suggestions/matches please select prominent landmark or community/society gate as a pickup point.</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Container className="d-flex justify-content-center align-items-center mb-3">
                          <Row style={{ height: "100%", width: "100%" }}>
                            <GoogleMap
                              zoom={15}
                              center={defaultStartCenter}
                              mapContainerStyle={{ width: "100%", height: "50vh" }}
                              onClick={handleMapClickStart}
                              options={{
                                types: ["(regions)"],
                                componentRestrictions: { country: "PK" },
                              }}
                            >
                              <MarkerF
                                position={markerPositionStart}
                                icon={{
                                  url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                                }}
                              />
                            </GoogleMap>
                          </Row>
                        </Container>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="contained" className="btn-custom1 mx-2 border-0 px-4 py-2 rounded rounded-2 text-white fw-bold" onClick={handleCloseStartModal}>
                          Select
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal show={showEndModal} onHide={handleCloseEndModal}>
                      <Modal.Header className="d-block" >
                        <Modal.Title>Select Drop-off Location</Modal.Title>
                        <Modal.Title className="text-danger fs-7">To get maximum suggestions/matches please select prominent landmark or community/society gate as a pickup point</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Container className="d-flex justify-content-center align-items-center mb-3">
                          <Row style={{ height: "100%", width: "100%" }}>
                            <GoogleMap
                              zoom={15}
                              // center={
                              //  defaultStartCenter ? defaultStartCenter : defaultEndCenter
                              // }
                              center={defaultEndCenter}
                              mapContainerStyle={{ width: "100%", height: "50vh" }}
                              onClick={handleMapClickEnd}
                              options={{
                                types: ["(regions)"],
                                componentRestrictions: { country: "PK" },
                              }}
                            >

                              <MarkerF
                                position={markerPositionEnd}
                                icon={{
                                  url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
                                }}
                              />

                            </GoogleMap>
                          </Row>
                        </Container>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="contained" className="btn-custom1 mx-2 border-0 px-4 py-2 rounded rounded-2 text-white fw-bold" onClick={handleCloseEndModal}>
                          Select
                        </Button>
                      </Modal.Footer>
                    </Modal>


                    <div className="row mb-3 shadow shadow-sm">
                      <div
                        className="col-md-12 px-2 py-3 form-color-field"
                      // style={{ backgroundColor: "#cddbd9" }}
                      >
                        <h2 className="text-success mb-3 text-center">
                          Timing
                        </h2>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustomtime1"
                          className="mb-2 mt-3"
                        >


                          <div className="d-flex justify-content-between align-items-center">
                            <Form.Label className="text-black fs-6">
                              Start Time
                              {/* (From start point to destination +/- 30 Minutes) */}
                            </Form.Label>
                            <p
                              className="colorplace text-danger"
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            // onClick={AddNewStart}
                            >
                              <Tooltip title={<h6 className="px-2">{"You can specify the times you are available for commuting by selecting both the start and return times."}</h6>}>
                                <Link
                                  // to='/notification'
                                  className='mx-1 h-15px d-inline-block'
                                  style={{ cursor: "pointer" }}
                                >
                                  <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                                </Link>
                              </Tooltip>

                            </p></div>
                          <Form.Select
                            aria-label="Default select example"
                            className="text-secondary"
                            value={selectedHomeTime}
                            onChange={(e) => {
                              const selectedOption = e.target.options[e.target.selectedIndex];
                              const selectedValue = selectedOption.value;
                              const selectedId = selectedOption.getAttribute("data-id");

                              // Set the ID
                              setSelectedHomeTime(selectedValue)

                              // Set the value
                              setSelectedHomeTimeValue(selectedId);
                            }}
                            required
                          >
                            <option value="" hidden>
                              Start Time
                            </option>
                            {homeTimeSlots?.map((time) => (
                              <option key={time.id} value={time.id} data-id={time.time_string}>
                                {time.time_string}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustomtime2"
                          className="mb-2 mt-3"
                        >
                          <Form.Label className="text-black fs-6">
                            Return Time
                            {/* (From destination to start point +/- 30 Minutes) */}
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            className="text-secondary"
                            value={selectedOfficeTime}
                            onChange={(e) => setSelectedOfficeTime(e.target.value)}
                            required
                          >
                            <option value="" hidden>
                              Return Time
                            </option>
                            {filteredOfficeTimeSlots?.map((time) => (
                              <option key={time.id} value={time.id}>
                                {time.time_string}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>
                    <Row className="my-3 form-color-field">
                    <Form.Group as={Col} md="12" controlId="validationCustom10">
                      <Form.Label className="pt-3 px-3 text-black">
                        I Commute (Select Days)
                      </Form.Label>
                    </Form.Group>

                    <div className="row d-flex px-4">
                      <div className="col d-flex flex-wrap">
                        {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
                          <div key={day} className="me-3">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="group1"
                                  value={day}
                                  color="success"
                                  checked={daysSelected.includes(day)}
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label={day}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </Row>

                    {/* {daysSelected} */}

                    <Row className="mb-3 py-3 px-3 shadow shadow-sm form-color-field">

                      <Form
                        className=" p-3 top-form" noValidate validated={validated} onSubmit={handleSubmit}
                      >
                        <div className="row mb-3 shadow shadow-sm">
                          <div
                            className="col-md-12 px-2 py-3 form-color-field"
                          >
                            <h2 className="text-success mb-3 text-center">
                              Car Details
                            </h2>
                            <Form.Group as={Col} md="12" controlId="validationCustom18" className="mb-2">
                              <Form.Label className="text-dark fs-6">Car Brand</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                className="text-secondary"
                                value={selectedCarBrand}
                                onChange={handleCarBrandChange}
                                error={!isCarBrandValid && selectedCarBrand !== ""}
                                helperText={
                                  !isCarBrandValid &&
                                  selectedCarBrand !== "" &&
                                  "Please enter a valid 24-digit IBAN."
                                }
                                // value={selectedModelName}
                                // onChange={(e) => setSelectedModelName(e.target.value)}
                                placeholder="Enter your Car Brand (Honda, Toyota etc)"
                                defaultValue=""
                              />
                     
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustom19" className="mb-2">
                              <Form.Label className="text-dark fs-6">
                                Model Name
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                className="text-secondary"
                                value={selectedModelName}
                                onChange={(e) => setSelectedModelName(e.target.value)}
                                placeholder="Model Name (Civic, City etc)"
                                defaultValue=""
                              />
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustom20" className="mb-2">
                              <Form.Label className="text-dark fs-6">
                                Manufacturing Year
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="text-secondary"
                                value={selectedManYear}
                                onChange={(e) => setSelectedManYear(e.target.value)}
                                required
                              >
                                <option value="" hidden>Manufacturing Year</option>
                                {manYear?.map((man) => (
                                  <option key={man.id} value={man.car_year_ranges}>
                                    {man.car_year_ranges}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>



                            <Form.Group as={Col} md="12" controlId="validationCustom22" className="mb-2">
                              <Form.Label className="text-dark fs-6">
                                Car CC
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="text-secondary"
                                value={selectedCarCC}
                                onChange={(e) => setSelectedCarCC(e.target.value)}
                                required
                              >
                                <option value="" hidden>Select Car CC</option>
                                {carCC?.map((car) => (
                                  <option key={car.id} value={car.car_cc}>
                                    {car.car_cc}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>

                            

                          </div>
                        </div>

                      

                        <div className="row mb-3 shadow shadow-sm">
                          <div
                            className="col-md-12 px-2 py-3 form-color-field"

                          >
                           
                          
                          </div>
                        </div>

                       


                        <Row >
                         
                        </Row>
                        <Row >
                        </Row>

                        <Row >

                        </Row>

          

                        <Row >
                        </Row>
                        <Row >
                         
                        
                        </Row>

                       
                        <Row >
                          
                          
                        </Row>
                        <Row >

                          

                        </Row>
          

                        {showmydriver && (
                          <>

                            <div className="row mb-3 mt-2 shadow shadow-sm">
                              <div
                                className="col-md-12 px-2 py-3 form-color-field"
                              >
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom36"
                                  className="mb-2"
                                >
                                  <h2 className="text-success mb-3 text-center">
                                    Driver's Details
                                  </h2>
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom37"
                                  className="mb-2"
                                >
                                  <Form.Label className="text-dark fs-6">
                                    Name
                                  </Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    className={`${isValidDriverName ? '' : 'is-invalid'}`}
                                    placeholder="Name"
                                    value={inputDriverName}
                                    onChange={handleDriverNameChange}
                                    onKeyPress={preventNumbers1}
                                  />

                                  {!isValidDriverName && (
                                    <div className="invalid-feedback">
                                      Full Name must contain only alphabetic characters and be at least 4 characters long
                                    </div>
                                  )}
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom38"
                                  className="mb-2"
                                >
                                  <Form.Label className="text-dark fs-6">
                                    CNIC
                                  </Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    className={`${isValidCnic1 ? '' : 'is-invalid'}`}
                                    placeholder="1234512345671"
                                    value={inputDriverCnicNumber}

                                    onChange={handleCnicChange1}

                                  />
                                  {!isValidCnic1 && (
                                    <div className="invalid-feedback">
                                      Please enter a valid CNIC without dashes in the format 1234512345671.
                                    </div>
                                  )}
                                </Form.Group>

                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom39"
                                  className="mb-2"
                                >
                                  <div className="d-flex justify-content-between align-items-center pt-1">
                                    <Form.Label className="text-dark fs-6">
                                      Upload CNIC (Front)
                                    </Form.Label>
                                    <p
                                      className="colorplace text-danger"
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",

                                      }}

                                    >
                                      <Tooltip title={<h6 className="px-2">{"Please submit a high-quality image of your CNIC in one of the specified formats: jpg, png, jpeg, or heic, ensuring that all the information is clearly legible. The file size should not exceed 5MB."}</h6>}>
                                        <Link

                                          className='mx-1 h-15px d-inline-block'
                                          style={{ cursor: "pointer" }}
                                        >
                                          <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                                        </Link>
                                      </Tooltip>

                                    </p></div>
                                  {selectedCnicFrontDriver ? (

                                    <div className="alert alert-light py-2 alert-dismissible fade show" role="alert">
                                      {selectedCnicFrontDriver.name}
                                      <button type="button" className="btn-close py-2 mt-1" data-bs-dismiss="alert" aria-label="Close" onClick={handleCnicFrontDriverPreview}></button>
                                    </div>
                                  ) : (
                                    <Form.Control type="file" accept="image/png, image/jpeg" required onChange={handleCnicFrontDriver} />)}

                                </Form.Group>
                              
                              </div>
                            </div>

                            <div className="row mb-3 mt-2 shadow shadow-sm">
                              <div
                                className="col-md-12 px-2 py-3 form-color-field"
                              >
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom41"
                                  className="mb-2"
                                >
                                  <h2 className="text-success mb-3 text-center">
                                    License Details
                                  </h2>
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom42"
                                  className="mb-2"
                                >
                                  <Form.Label className="text-dark fs-6">
                                    Driving License No.
                                  </Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    className="text-secondary"
                                    placeholder="License No."
                                    value={inputDriverLicenseNumber}
                                    onChange={(e) => setInputDriverLicenseNumber(e.target.value)}
                                    defaultValue=""
                                  />
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom43"
                                  className="mb-2"
                                >
                                  <Form.Label className="text-dark fs-6">
                                    Valid Upto
                                  </Form.Label>
                                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                      label={"MM/DD/YY"}
                                      className="bg-white"
                                      slotProps={{
                                        textField: { size: "small", color: "success" },
                                      }}
                                      sx={{ width: "100%" }}
                                      value={inputDriverValidUpto}
                                      onChange={handleValidDriverChange}
                                      shouldDisableDate={shouldDisableDate}
                                      disablePast
                                    />
                                  </LocalizationProvider>
                                  
                                </Form.Group>
                               
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom44"
                                  className="mb-2"
                                >
                                  <div className="d-flex justify-content-between align-items-center pt-1">
                                    <Form.Label className="text-dark fs-6">
                                      Upload License (Front)
                                    </Form.Label>
                                    <p
                                      className="colorplace text-danger"
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",

                                      }}

                                    >
                                      <Tooltip title={<h6 className="px-2">{"Please submit a high-quality image of your CNIC in one of the specified formats: jpg, png, jpeg, or heic, ensuring that all the information is clearly legible. The file size should not exceed 5MB."}</h6>}>
                                        <Link

                                          className='mx-1 h-15px d-inline-block'
                                          style={{ cursor: "pointer" }}
                                        >
                                          <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                                        </Link>
                                      </Tooltip>

                                    </p></div>
                                  {selectedLicenseFrontDriver ? (

                                    <div className="alert alert-light py-2 alert-dismissible fade show" role="alert">
                                      {selectedLicenseFrontDriver.name}
                                      <button type="button" className="btn-close py-2 mt-1" data-bs-dismiss="alert" aria-label="Close" onClick={handleLicenseFrontDriverPreview}></button>
                                    </div>
                                  ) : (
                                    <Form.Control type="file" accept="image/png, image/jpeg" required onChange={handleLicenseFrontDriver} />

                                  )}
                  
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom45"
                                  className="mb-2"
                                >
                                  <div className="d-flex justify-content-between align-items-center pt-1">
                                    <Form.Label className="text-dark fs-6">
                                      Upload License (Back)
                                    </Form.Label>
                                    <p
                                      className="colorplace text-danger"
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",

                                      }}

                                    >
                                      <Tooltip title={<h6 className="px-2">{"Please submit a high-quality image of your CNIC in one of the specified formats: jpg, png, jpeg, or heic, ensuring that all the information is clearly legible. The file size should not exceed 5MB."}</h6>}>
                                        <Link

                                          className='mx-1 h-15px d-inline-block'
                                          style={{ cursor: "pointer" }}
                                        >
                                          <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                                        </Link>
                                      </Tooltip>

                                    </p></div>
                                  {selectedLicenseBackDriver ? (

                                    <div className="alert alert-light py-2 alert-dismissible fade show" role="alert">
                                      {selectedLicenseBackDriver.name}
                                      <button type="button" className="btn-close py-2 mt-1" data-bs-dismiss="alert" aria-label="Close" onClick={handleLicenseBackDriverPreview}></button>
                                    </div>
                                  ) : (
                                    <Form.Control type="file" accept="image/png, image/jpeg" required onChange={handleLicenseBackDriver} />

                                  )}

                                 
                                </Form.Group>

                              </div>
                            </div>
                          

                            <Row className="">
                            
                            </Row>
                            <Row className="">
                             
                            </Row>
                            <Row className="">
                             
                            </Row>
                            <Row className="">
                             
                          
                            </Row>
                          </>
                        )}


                        {showboth && (<>

                      
                          <div className="row mb-3 mt-2 shadow shadow-sm">
                            <div
                              className="col-md-12 px-2 py-3 form-color-field"
                            >
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom30"
                                className="mb-2"
                              >
                                <h2 className="text-success mb-3 text-center">
                                  MySelf License Details
                                </h2>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom31"
                                className="mb-2"
                              >
                                <Form.Label className="text-dark fs-6">
                                  Driving License No.
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  className="text-secondary"
                                  placeholder="License No."
                                  value={inputDrivingLicenseMySelf}
                                  onChange={(e) => setInputDrivingLicenseMySelf(e.target.value)}
                                  defaultValue=""
                                />
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom32"
                                className="mb-2"
                              >
                                <Form.Label className="text-dark fs-6">
                                  Valid Upto
                                </Form.Label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    label={"MM/DD/YY"}
                                    className="bg-white"
                                    slotProps={{
                                      textField: { size: "small", color: "success" },
                                    }}
                                    sx={{ width: "100%" }}
                                    value={inputValidUptoMySelf}
                                    onChange={handleValidChange}
                                    shouldDisableDate={shouldDisableDate}
                                    disablePast
                                  />
                                </LocalizationProvider>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom33"
                                className="mb-2"
                              >
                                <Form.Label text-dark fs-6>
                                  Place of Issue
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  className="text-secondary"
                                  placeholder="Place of issue"
                                  value={inputPlaceIssueMySelf}
                                  onChange={(e) => setInputPlaceIssueMySelf(e.target.value)}
                                  defaultValue=""
                                />
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom34"
                                className="mb-2"
                              >
                                <div className="d-flex justify-content-between align-items-center pt-1">
                                  <Form.Label className="text-dark fs-6">
                                    Upload License (Front)
                                  </Form.Label>
                                  <p
                                    className="colorplace text-danger"
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "underline",

                                    }}

                                  >
                                    <Tooltip title={<h6 className="px-2">{"Please submit a high-quality image of your CNIC in one of the specified formats: jpg, png, jpeg, or heic, ensuring that all the information is clearly legible. The file size should not exceed 5MB."}</h6>}>
                                      <Link

                                        className='mx-1 h-15px d-inline-block'
                                        style={{ cursor: "pointer" }}
                                      >
                                        <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                                      </Link>
                                    </Tooltip>

                                  </p></div>
                                {selectedLicenseFrontDriver ? (

                                  <div className="alert alert-light py-2 alert-dismissible fade show" role="alert">
                                    {selectedLicenseFrontDriver.name}
                                    <button type="button" className="btn-close py-2 mt-1" data-bs-dismiss="alert" aria-label="Close" onClick={handleLicenseFrontDriverPreview}></button>
                                  </div>
                                ) : (
                                  <Form.Control type="file" accept="image/png, image/jpeg" required onChange={handleLicenseFrontDriver} />

                                )}
                               
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom35"
                                className="mb-2"
                              >
                                <div className="d-flex justify-content-between align-items-center pt-1">
                                  <Form.Label className="text-dark fs-6">
                                    Upload License (Back)
                                  </Form.Label>
                                  <p
                                    className="colorplace text-danger"
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "underline",

                                    }}

                                  >
                                    <Tooltip title={<h6 className="px-2">{"Please submit a high-quality image of your CNIC in one of the specified formats: jpg, png, jpeg, or heic, ensuring that all the information is clearly legible. The file size should not exceed 5MB."}</h6>}>
                                      <Link

                                        className='mx-1 h-15px d-inline-block'
                                        style={{ cursor: "pointer" }}
                                      >
                                        <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                                      </Link>
                                    </Tooltip>

                                  </p></div>
                                {selectedLicenseBackDriver ? (

                                  <div className="alert alert-light py-2 alert-dismissible fade show" role="alert">
                                    {selectedLicenseBackDriver.name}
                                    <button type="button" className="btn-close py-2 mt-1" data-bs-dismiss="alert" aria-label="Close" onClick={handleLicenseBackDriverPreview}></button>
                                  </div>
                                ) : (
                                  <Form.Control type="file" accept="image/png, image/jpeg" required onChange={handleLicenseBackDriver} />

                                )}
                               
                              </Form.Group>
                            </div></div>
                          <div className="row mb-3 mt-2 shadow shadow-sm">
                            <div
                              className="col-md-12 px-2 py-3 form-color-field"
                            >


                              <h2 className="text-success mb-3 text-center">
                                My Driver's Details
                              </h2>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom46"
                                className="mb-2"
                              >
                                <Form.Label className="text-dark fs-6">
                                  Name
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  className={`${isValidDriverName ? '' : 'is-invalid'}`}
                                  placeholder="Name"
                                  value={inputDriverName}
                                  onChange={handleDriverNameChange}
                                  onKeyPress={preventNumbers1}
                                />

                                {!isValidDriverName && (
                                  <div className="invalid-feedback">
                                    Full Name must contain only alphabetic characters and be at least 4 characters long
                                  </div>
                                )}
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom38"
                                className="mb-2"
                              >
                                <Form.Label className="text-dark fs-6">
                                  CNIC
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  className={`${isValidCnic1 ? '' : 'is-invalid'}`}
                                  placeholder="1234512345671"
                                  value={inputDriverCnicNumber}
                                  onChange={handleCnicChange1}
           
                                />
                                {!isValidCnic1 && (
                                  <div className="invalid-feedback">
                                    Please enter a valid CNIC without dashed in the format 1234512345671.
                                  </div>
                                )}
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom48"
                                className="mb-2"
                              >
                                <div className="d-flex justify-content-between align-items-center pt-1">
                                  <Form.Label className="text-dark fs-6">
                                    Upload CNIC (Front)
                                  </Form.Label>
                                  <p
                                    className="colorplace text-danger"
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "underline",

                                    }}

                                  >
                                    <Tooltip title={<h6 className="px-2">{"Please submit a high-quality image of your CNIC in one of the specified formats: jpg, png, jpeg, or heic, ensuring that all the information is clearly legible. The file size should not exceed 5MB."}</h6>}>
                                      <Link

                                        className='mx-1 h-15px d-inline-block'
                                        style={{ cursor: "pointer" }}
                                      >
                                        <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                                      </Link>
                                    </Tooltip>

                                  </p></div>
                                {selectedCnicFrontDriver ? (

                                  <div className="alert alert-light py-2 alert-dismissible fade show" role="alert">
                                    {selectedCnicFrontDriver.name}
                                    <button type="button" className="btn-close py-2 mt-1" data-bs-dismiss="alert" aria-label="Close" onClick={handleCnicFrontDriverPreview}></button>
                                  </div>
                                ) : (
                                  <Form.Control type="file" accept="image/png, image/jpeg" required onChange={handleCnicFrontDriver} />)}

                          
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom49"
                                className="mb-2"
                              >
                                <div className="d-flex justify-content-between align-items-center pt-1">
                                  <Form.Label className="text-dark">
                                    Upload CNIC (Back)
                                  </Form.Label>
                                  <p
                                    className="colorplace text-danger"
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "underline",

                                    }}

                                  >
                                    <Tooltip title={<h6 className="px-2">{"Please submit a high-quality image of your CNIC in one of the specified formats: jpg, png, jpeg, or heic, ensuring that all the information is clearly legible. The file size should not exceed 5MB."}</h6>}>
                                      <Link

                                        className='mx-1 h-15px d-inline-block'
                                        style={{ cursor: "pointer" }}
                                      >
                                        <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                                      </Link>
                                    </Tooltip>

                                  </p></div>
                                {selectedCnicBackDriver ? (

                                  <div className="alert alert-light py-2 alert-dismissible fade show" role="alert">
                                    {selectedCnicBackDriver.name}
                                    <button type="button" className="btn-close py-2 mt-1" data-bs-dismiss="alert" aria-label="Close" onClick={handleCnicBackDriverPreview}></button>
                                  </div>
                                ) : (
                                  <Form.Control type="file" accept="image/png, image/jpeg" required onChange={handleCnicBackDriver} />)}

                          
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom42"
                                className="mb-2"
                              >
                                <Form.Label className="text-dark fs-6">
                                  Driving License No.
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  className="text-secondary"
                                  placeholder="License No."
                                  value={inputDriverLicenseNumber}
                                  onChange={(e) => setInputDriverLicenseNumber(e.target.value)}
                                  defaultValue=""
                                />
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom43"
                                className="mb-2"
                              >
                                <Form.Label className="text-dark fs-6">
                                  Valid Upto
                                </Form.Label>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                  <DatePicker
                                    label={"MM/DD/YY"}
                                    className="bg-white"
                                    slotProps={{
                                      textField: { size: "small", color: "success" },
                                    }}
                                    sx={{ width: "100%" }}
                                    value={inputDriverValidUpto}
                                    onChange={handleValidDriverChange}
                                    shouldDisableDate={shouldDisableDate}
                                    disablePast
                                  />
                                </LocalizationProvider>
                              
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom44"
                                className="mb-2"
                              >
                                <div className="d-flex justify-content-between align-items-center pt-1">
                                  <Form.Label className="text-dark fs-6">
                                    Upload License (Front)
                                  </Form.Label>
                                  <p
                                    className="colorplace text-danger"
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "underline",

                                    }}

                                  >
                                    <Tooltip title={<h6 className="px-2">{"Please submit a high-quality image of your CNIC in one of the specified formats: jpg, png, jpeg, or heic, ensuring that all the information is clearly legible. The file size should not exceed 5MB."}</h6>}>
                                      <Link

                                        className='mx-1 h-15px d-inline-block'
                                        style={{ cursor: "pointer" }}
                                      >
                                        <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                                      </Link>
                                    </Tooltip>

                                  </p></div>
                                {selectedLicenseFrontDriver ? (

                                  <div className="alert alert-light py-2 alert-dismissible fade show" role="alert">
                                    {selectedLicenseFrontDriver.name}
                                    <button type="button" className="btn-close py-2 mt-1" data-bs-dismiss="alert" aria-label="Close" onClick={handleLicenseFrontDriverPreview}></button>
                                  </div>
                                ) : (
                                  <Form.Control type="file" accept="image/png, image/jpeg" required onChange={handleLicenseFrontDriver} />

                                )}
                                
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom45"
                                className="mb-2"
                              >
                                <div className="d-flex justify-content-between align-items-center pt-1">
                                  <Form.Label className="text-dark fs-6">
                                    Upload License (Back)
                                  </Form.Label>
                                  <p
                                    className="colorplace text-danger"
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "underline",

                                    }}

                                  >
                                    <Tooltip title={<h6 className="px-2">{"Please submit a high-quality image of your CNIC in one of the specified formats: jpg, png, jpeg, or heic, ensuring that all the information is clearly legible. The file size should not exceed 5MB."}</h6>}>
                                      <Link

                                        className='mx-1 h-15px d-inline-block'
                                        style={{ cursor: "pointer" }}
                                      >
                                        <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                                      </Link>
                                    </Tooltip>

                                  </p></div>
                                {selectedLicenseBackDriver ? (

                                  <div className="alert alert-light py-2 alert-dismissible fade show" role="alert">
                                    {selectedLicenseBackDriver.name}
                                    <button type="button" className="btn-close py-2 mt-1" data-bs-dismiss="alert" aria-label="Close" onClick={handleLicenseBackDriverPreview}></button>
                                  </div>
                                ) : (
                                  <Form.Control type="file" accept="image/png, image/jpeg" required onChange={handleLicenseBackDriver} />

                                )}

                              </Form.Group>
                            </div></div>
                         



                        </>)
                        }


                        <Stack
                          direction="row"
                          className="mb-4 mt-3"
                          spacing={2}
                          style={{ justifyContent: "right" }}
                        >
 
                          <Button
                            variant=""
                            className="btn-custom1 mx-2 border-0 px-4 py-2 rounded rounded-2 text-white fw-bold"
                            onClick={() => {
                              handleDriver();
                            }}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <span>
                                <i className="fa fa-spinner fa-spin" /> Submitting...
                              </span>
                            ) : (
                              'Submit'
                            )}
                          </Button>
                        </Stack>
                      </Form>

                    
                    </Row>
                   
                  </Form>

                </div>
              </div>
            </div>
          </div>

        </>
      )}

      {showDriverForm && (
        <>
          <div className="main-bg">
            <div className="containter p-5 position-relative">
              <div className="area" >
                <ul className="circles">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div >
              <div className="row justify-content-center">
                <div className="col-md-6 bg-white  mt-5 mb-5">
                  <div
                    className="row shadow form-color-header"
                  >
                    <h1
                      className="text-center text-white py-4"

                    >
                      {" "}
                      Registration Form 2/2
                    </h1>{" "}
                  </div>

                  <Form
                    className=" p-3 top-form" noValidate validated={validated} onSubmit={handleSubmit}
                  >
                    <div className="row mb-3 shadow shadow-sm">
                      <div
                        className="col-md-12 px-2 py-3 form-color-field"
                      >
                        <h2 className="text-success mb-3 text-center">
                          Car Details
                        </h2>
                        <Form.Group as={Col} md="12" controlId="validationCustom18" className="mb-2">
                          <Form.Label className="text-dark fs-6">Car Brand</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            className="text-secondary"
                            value={selectedCarBrand}
                            onChange={handleCarBrandChange}
                            error={!isCarBrandValid && selectedCarBrand !== ""}
                            helperText={
                              !isCarBrandValid &&
                              selectedCarBrand !== "" &&
                              "Please enter a valid 24-digit IBAN."
                            }
                            placeholder="Enter your Car Brand (Honda, Toyota etc)"
                            defaultValue=""
                          />
               
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationCustom19" className="mb-2">
                          <Form.Label className="text-dark fs-6">
                            Model Name
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            className="text-secondary"
                            value={selectedModelName}
                            onChange={(e) => setSelectedModelName(e.target.value)}
                            placeholder="Model Name (Civic, City etc)"
                            defaultValue=""
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationCustom20" className="mb-2">
                          <Form.Label className="text-dark fs-6">
                            Manufacturing Year
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            className="text-secondary"
                            value={selectedManYear}
                            onChange={(e) => setSelectedManYear(e.target.value)}
                            required
                          >
                            <option value="" hidden>Manufacturing Year</option>
                            {manYear?.map((man) => (
                              <option key={man.id} value={man.car_year_ranges}>
                                {man.car_year_ranges}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationCustom21" className="mb-2">
                          <Form.Label className="text-dark fs-6">
                            My Car has AC
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            className="text-secondary"
                            value={selectedCarAC}
                            onChange={(e) => setSelectedCarAC(e.target.value)}
                            required
                          >
                            <option value="" hidden>Select from Dropdown</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationCustom22" className="mb-2">
                          <Form.Label className="text-dark fs-6">
                            Car CC
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            className="text-secondary"
                            value={selectedCarCC}
                            onChange={(e) => setSelectedCarCC(e.target.value)}
                            required
                          >
                            <option value="" hidden>Select Car CC</option>
                            {carCC?.map((car) => (
                              <option key={car.id} value={car.car_cc}>
                                {car.car_cc}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>

                      </div>
                    </div>

                    <div className="row mb-3 shadow shadow-sm">
                      <div
                        className="col-md-12 px-2 py-3 form-color-field"
                      >
                        <h2 className="text-success mb-3 text-center">
                          Car Registration
                        </h2>
                        <Form.Group as={Col} md="12" controlId="validationCustom23" className="mb-2">
                          <Form.Label className="text-dark fs-6">
                            Registration Year
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            className="text-secondary"
                            value={selectedRegYear}
                            onChange={(e) => setSelectedRegYear(e.target.value)}
                            required
                          >
                            <option value="" hidden>Registration Year</option>
                            {filteredRegYears.map((reg) => (
                              <option key={reg.id} value={reg.car_year_ranges}>
                                {reg.car_year_ranges}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
 
                        <Form.Group as={Col} md="12" controlId="validationCustom25" className="mb-2">
                          <Form.Label className="text-dark fs-6">
                            Registration Number
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            className="text-secondary"
                            value={selectedRegNumber}
                            onChange={(e) => setSelectedRegNumber(e.target.value)}
                            placeholder="Registration Number"
                            defaultValue=""
                          />
                        </Form.Group>
                      </div>
                    </div>

            
                    {showmyself && (
                      <>

                      </>
                    )}

                    {showmydriver && (
                      <>

                        <div className="row mb-3 mt-2 shadow shadow-sm">
                          <div
                            className="col-md-12 px-2 py-3 form-color-field"
                          >
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom36"
                              className="mb-2"
                            >
                              <h2 className="text-success mb-3 text-center">
                                Driver's Details
                              </h2>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom37"
                              className="mb-2"
                            >
                              <Form.Label className="text-dark fs-6">
                                Name
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                className={`${isValidDriverName ? '' : 'is-invalid'}`}
                                placeholder="Name"
                                value={inputDriverName}
                                onChange={handleDriverNameChange}
                                onKeyPress={preventNumbers1}
                              />

                              {!isValidDriverName && (
                                <div className="invalid-feedback">
                                  Full Name must contain only alphabetic characters and be at least 4 characters long
                                </div>
                              )}
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom38"
                              className="mb-2"
                            >
                              <Form.Label className="text-dark fs-6">
                                CNIC
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                className={`${isValidCnic1 ? '' : 'is-invalid'}`}
                                placeholder="1234512345671"
                                value={inputDriverCnicNumber}
                                onChange={(e) => setInputDriverCnicNumber(e.target.value)}
                                onChange={handleCnicChange1}
                                defaultValue=""
                                maxLength={13}
                              />
                              {!isValidCnic1 && (
                                <div className="invalid-feedback">
                                  Please enter a valid CNIC without dashes in the format 1234512345671.
                                </div>
                              )}
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom39"
                              className="mb-2"
                            >
                              <div className="d-flex justify-content-between align-items-center pt-1">
                                <Form.Label className="text-dark fs-6">
                                  Upload CNIC (Front)
                                </Form.Label>
                                <p
                                  className="colorplace text-danger"
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",

                                  }}

                                >
                                  <Tooltip title={<h6 className="px-2">{"Please submit a high-quality image of your CNIC in one of the specified formats: jpg, png, jpeg, or heic, ensuring that all the information is clearly legible. The file size should not exceed 5MB."}</h6>}>
                                    <Link

                                      className='mx-1 h-15px d-inline-block'
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                                    </Link>
                                  </Tooltip>

                                </p></div>
                              {selectedCnicFrontDriver ? (

                                <div className="alert alert-light py-2 alert-dismissible fade show" role="alert">
                                  {selectedCnicFrontDriver.name}
                                  <button type="button" className="btn-close py-2 mt-1" data-bs-dismiss="alert" aria-label="Close" onClick={handleCnicFrontDriverPreview}></button>
                                </div>
                              ) : (
                                <Form.Control type="file" accept="image/png, image/jpeg" required onChange={handleCnicFrontDriver} />)}

                              <Form.Text className="text-danger" style={{ color: "#000" }}>
                                The picture must be of type: jpg, png, jpeg, heic (max size: 10MB).
                              </Form.Text>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom40"
                              className="mb-2"
                            >
                              <div className="d-flex justify-content-between align-items-center pt-1">
                                <Form.Label className="text-dark">
                                  Upload CNIC (Back)
                                </Form.Label>
                                <p
                                  className="colorplace text-danger"
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",

                                  }}

                                >
                                  <Tooltip title={<h6 className="px-2">{"Please submit a high-quality image of your CNIC in one of the specified formats: jpg, png, jpeg, or heic, ensuring that all the information is clearly legible. The file size should not exceed 5MB."}</h6>}>
                                    <Link

                                      className='mx-1 h-15px d-inline-block'
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                                    </Link>
                                  </Tooltip>

                                </p></div>
                              {selectedCnicBackDriver ? (

                                <div className="alert alert-light py-2 alert-dismissible fade show" role="alert">
                                  {selectedCnicBackDriver.name}
                                  <button type="button" className="btn-close py-2 mt-1" data-bs-dismiss="alert" aria-label="Close" onClick={handleCnicBackDriverPreview}></button>
                                </div>
                              ) : (
                                <Form.Control type="file" accept="image/png, image/jpeg" required onChange={handleCnicBackDriver} />)}
                              <Form.Text className="text-danger" style={{ color: "#000" }}>
                                The picture must be of type: jpg, png, jpeg, heic (max size: 10MB).
                              </Form.Text>
                            </Form.Group>
                          </div></div>

                        <div className="row mb-3 mt-2 shadow shadow-sm">
                          <div
                            className="col-md-12 px-2 py-3 form-color-field"
                          >
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom41"
                              className="mb-2"
                            >
                              <h2 className="text-success mb-3 text-center">
                                License Details
                              </h2>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom42"
                              className="mb-2"
                            >
                              <Form.Label className="text-dark fs-6">
                                Driving License No.
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                className="text-secondary"
                                placeholder="License No."
                                value={inputDriverLicenseNumber}
                                onChange={(e) => setInputDriverLicenseNumber(e.target.value)}
                                defaultValue=""
                              />
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom43"
                              className="mb-2"
                            >
                              <Form.Label className="text-dark fs-6">
                                Valid Upto
                              </Form.Label>
                              <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DatePicker
                                  label={"MM/DD/YY"}
                                  className="bg-white"
                                  slotProps={{
                                    textField: { size: "small", color: "success" },
                                  }}
                                  sx={{ width: "100%" }}
                                  value={inputDriverValidUpto}
                                  onChange={handleValidDriverChange}
                                  shouldDisableDate={shouldDisableDate}
                                  disablePast
                                />
                              </LocalizationProvider>
                              <Form.Control
                                required
                                type="text"
                                className="text-secondary"
                                placeholder="Enter Here"
                                value={inputDriverValidUpto}
                                onChange={(e) => setInputDriverValidUpto(e.target.value)}
                                defaultValue=""
                              />
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom33"
                              className="mb-2"
                            >
                              <Form.Label text-dark fs-6>
                                Place of Issue
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                className="text-secondary"
                                placeholder="Place of issue"
                                value={inputPlaceIssueMySelf}
                                onChange={(e) => setInputPlaceIssueMySelf(e.target.value)}
                                defaultValue=""
                              />
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom44"
                              className="mb-2"
                            >
                              <div className="d-flex justify-content-between align-items-center pt-1">
                                <Form.Label className="text-dark fs-6">
                                  Upload License (Front)
                                </Form.Label>
                                <p
                                  className="colorplace text-danger"
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",

                                  }}

                                >
                                  <Tooltip title={<h6 className="px-2">{"Please submit a high-quality image of your CNIC in one of the specified formats: jpg, png, jpeg, or heic, ensuring that all the information is clearly legible. The file size should not exceed 5MB."}</h6>}>
                                    <Link

                                      className='mx-1 h-15px d-inline-block'
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                                    </Link>
                                  </Tooltip>

                                </p></div>
                              {selectedLicenseFrontDriver ? (

                                <div className="alert alert-light py-2 alert-dismissible fade show" role="alert">
                                  {selectedLicenseFrontDriver.name}
                                  <button type="button" className="btn-close py-2 mt-1" data-bs-dismiss="alert" aria-label="Close" onClick={handleLicenseFrontDriverPreview}></button>
                                </div>
                              ) : (
                                <Form.Control type="file" accept="image/png, image/jpeg" required onChange={handleLicenseFrontDriver} />

                              )}
                              <Form.Text className="text-danger" style={{ color: "#000" }}>
                                The picture must be of type: jpg, png, jpeg, heic (max size: 10MB).
                              </Form.Text>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom45"
                              className="mb-2"
                            >
                              <div className="d-flex justify-content-between align-items-center pt-1">
                                <Form.Label className="text-dark fs-6">
                                  Upload License (Back)
                                </Form.Label>
                                <p
                                  className="colorplace text-danger"
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",

                                  }}

                                >
                                  <Tooltip title={<h6 className="px-2">{"Please submit a high-quality image of your CNIC in one of the specified formats: jpg, png, jpeg, or heic, ensuring that all the information is clearly legible. The file size should not exceed 5MB."}</h6>}>
                                    <Link

                                      className='mx-1 h-15px d-inline-block'
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                                    </Link>
                                  </Tooltip>

                                </p></div>
                              {selectedLicenseBackDriver ? (

                                <div className="alert alert-light py-2 alert-dismissible fade show" role="alert">
                                  {selectedLicenseBackDriver.name}
                                  <button type="button" className="btn-close py-2 mt-1" data-bs-dismiss="alert" aria-label="Close" onClick={handleLicenseBackDriverPreview}></button>
                                </div>
                              ) : (
                                <Form.Control type="file" accept="image/png, image/jpeg" required onChange={handleLicenseBackDriver} />

                              )}

                              <Form.Text className="text-danger" style={{ color: "#000" }}>
                                The picture must be of type: jpg, png, jpeg, heic (max size: 10MB).
                              </Form.Text>
                            </Form.Group>
                          </div>
                        </div>
                        <Row className="mb-3 mt-3">
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-2"
                          >
                            <Form.Label className="text-dark fs-6">
                              Name
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              className="text-secondary"
                              placeholder="Name"
                              value={inputDriverName}
                              onChange={(e) => setInputDriverName(e.target.value)}
                              defaultValue=""
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-2"
                          >
                            <Form.Label className="text-dark fs-6">
                              CNIC
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              className="text-secondary"
                              placeholder="CNIC: xxxxxxxxxxxxx"
                              value={inputDriverCnicNumber}
                              onChange={(e) => setInputDriverCnicNumber(e.target.value)}
                              defaultValue=""
                              maxLength={13}
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-2"
                          >
                            <Form.Label className="text-dark fs-6">
                              Upload CNIC (front)
                            </Form.Label>
                            <Form.Control type="file" required onChange={handleCnicFrontDriver} />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom02"
                            className="mb-2"
                          >
                            <Form.Label className="text-dark">
                              Upload CNIC (back)
                            </Form.Label>
                            <Form.Control type="file" required onChange={handleCnicBackDriver} />
                          </Form.Group>
                        </Row>
                        <Row className="">
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Form.Label style={{ color: "#000" }}>
                              Upload CNIC (front)
                            </Form.Label>
                            <Form.Control type="file" required onChange={handleCnicFrontDriver} />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom02"
                          >
                            <Form.Label style={{ color: "#000" }}>
                              Upload CNIC (back)
                            </Form.Label>
                            <Form.Control type="file" required onChange={handleCnicBackDriver} />
                          </Form.Group>
                        </Row>
                        <Row className="">
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                          >
                            <Form.Label style={{ color: "#000" }}>
                              Driving Licence No.
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              className="colorplace"
                              placeholder="License No."
                              value={inputDriverLicenseNumber}
                              onChange={(e) => setInputDriverLicenseNumber(e.target.value)}
                              defaultValue=""
                            />
                          </Form.Group>
                        </Row>
                        <Row className="">
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                          >
                            <Form.Label style={{ color: "#000" }}>
                              Valid Upto
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              className="colorplace"
                              placeholder="Enter Here"
                              value={inputDriverValidUpto}
                              onChange={(e) => setInputDriverValidUpto(e.target.value)}
                              defaultValue=""
                            />
                          </Form.Group>
                        </Row>
                        <Row className="">
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Form.Label style={{ color: "#000" }}>
                              Upload License (front)
                            </Form.Label>
                            <Form.Control type="file" required onChange={handleLicenseFrontDriver} />
                          </Form.Group> *
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom02"
                          >
                            <Form.Label style={{ color: "#000" }}>
                              Upload License (back)
                            </Form.Label>
                            <Form.Control type="file" required onChange={handleLicenseBackDriver} />
                          </Form.Group>
                        </Row>
                      </>
                    )}

                    {showboth && (<>

                    </>)
                    }


                    <div
                      className="alert alert-info alert-dismissible fade show"
                      role="alert"
                    >
                      {" "}
                      <h6 className="text-left d-flex">
                        {" "}
                        <i className="fa-solid fa-lock fa-fw text-danger text-center pe-3"></i>
                        <li className="text-danger" style={{ listStyle: "none" }} >
                          {" "}
                          Your data is protected within a secured environment.
                        </li>
                      </h6>
                      <button
                        type=""
                        class="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                    </div>
                    <Stack
                      direction="row"
                      className="mb-4 mt-3"
                      spacing={2}
                      style={{ justifyContent: "right" }}
                    >
                      <Button
                        variant=""
                        className="btn-custom1 mx-2 border-0 px-4 py-2 rounded rounded-2 text-white fw-bold"
                        onClick={() => {
                          setShowDriverForm(false);
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        variant=""
                        className="btn-custom1 mx-2 border-0 px-4 py-2 rounded rounded-2 text-white fw-bold"
                        onClick={() => {
                          handleDriver();
                        }}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <span>
                            <i className="fa fa-spinner fa-spin" /> Submitting...
                          </span>
                        ) : (
                          'Submit'
                        )}
                      </Button>
                    </Stack>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DriverRegistration;