import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button,
  Snackbar, Alert, MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import { api } from '../../api';

const DriverForm = ({ open, onClose, driverEditingId, setDriverEditingId, setLastDriver }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    driver_image: null,
    license_image: null,
    license_no: '',
    validity_date: '',
    years_of_experience: 1,
    dob: ''
  });
  const [imagePreviews, setImagePreviews] = useState({ driver_image: null, license_image: null });
  const [errors, setErrors] = useState({});
  const [flashMessage, setFlashMessage] = useState({ message: '', severity: '' });

  useEffect(() => {
    if (open) {
      if (driverEditingId) {
        const fetchDriver = async () => {
          try {
            const response = await api.get(`/drivers/${driverEditingId}`);
            const driver = response.data;
            setFormData({
              name: driver.name,
              phone: driver.phone,
              driver_image: null,
              license_image: null,
              license_no: driver.license_no,
              validity_date: driver.validity_date,
              years_of_experience: driver.years_of_experience,
              dob: driver.dob
            });
            setImagePreviews({ driver_image: null, license_image: null });
            setErrors({}); // Reset errors when opening the form
          } catch (error) {
            console.error('Error fetching driver:', error);
          }
        };
        fetchDriver();
      } else {
        initializeForm();
      }
    }
  }, [open, driverEditingId]);

  const initializeForm = () => {
    setFormData({
      name: '',
      phone: '',
      driver_image: null,
      license_image: null,
      license_no: '',
      validity_date: '',
      years_of_experience: 1,
      dob: ''
    });
    setImagePreviews({ driver_image: null, license_image: null });
    setErrors({}); // Reset errors on form initialization
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData({ ...formData, [name]: type === 'number' ? parseInt(value, 10) : value });
  };

  const handleImageChange = (e, imageType) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, [imageType]: file });
      setImagePreviews({ ...imagePreviews, [imageType]: URL.createObjectURL(file) });
    }
  };

  const handleSubmit = async () => {
    // Reset errors before validation
    setErrors({});
    const { name, phone, license_no, validity_date, years_of_experience, dob, driver_image, license_image } = formData;

    let formErrors = {};
    if (!name) formErrors.name = 'Name is required';
    if (!phone) formErrors.phone = 'Phone number is required';
    if (!license_no) formErrors.license_no = 'License number is required';
    if (!validity_date) formErrors.validity_date = 'Validity date is required';
    // if (years_of_experience === null) formErrors.years_of_experience = 'Years of experience is required';
    // if (!dob) formErrors.dob = 'Date of birth is required';
    if (!driver_image) formErrors.driver_image = 'Driver image is required';
    if (!license_image) formErrors.license_image = 'License image is required';

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const formDataToSubmit = new FormData();
    for (const key in formData) {
      formDataToSubmit.append(key, formData[key]);
    }

    try {
      const request = driverEditingId
        ? api.put(`/drivers/${driverEditingId}`, formDataToSubmit,)
        : api.post('/drivers', formDataToSubmit,);

      const response = await request;
      const newDriver = response.data; // Get the ID from the response
      setLastDriver({
        'id': newDriver.id,
        'name': newDriver.name + " - " + newDriver.license_no
      });
      setDriverEditingId(newDriver.id);
      setFlashMessage({ message: driverEditingId ? 'Driver updated successfully!' : 'Driver added successfully!', severity: 'success' });
      onClose(newDriver.id);
    } catch (error) {
      console.error('Error saving driver:', error);
      const serverErrors = error.response?.data.errors || {};
      setErrors(prevErrors => ({ ...prevErrors, ...serverErrors }));
      setFlashMessage({ message: 'Error saving driver. Please try again.', severity: 'error' });
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{driverEditingId ? 'Edit Driver' : 'Add Driver'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            name="name"
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            label="Phone"
            name="phone"
            variant="outlined"
            value={formData.phone}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.phone}
            helperText={errors.phone}
          />
          <TextField
            label="License No"
            name="license_no"
            variant="outlined"
            value={formData.license_no}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.license_no}
            helperText={errors.license_no}
          />
          <TextField
            label="Validity Date"
            name="validity_date"
            type="date"
            variant="outlined"
            value={formData.validity_date}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.validity_date}
            helperText={errors.validity_date}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: {
                min: new Date().toISOString().split('T')[0], // Disables previous dates
              },
            }}
          />

          {/* <FormControl fullWidth margin="normal" error={!!errors.years_of_experience}>
            <InputLabel>Years of Experience</InputLabel>
            <Select
              name="years_of_experience"
              value={formData.years_of_experience || ''}
              onChange={handleChange}
              label= "Years of Experience"
            >
              <MenuItem value="">Select Experience</MenuItem>
              <MenuItem value={0}>Less than 1 year</MenuItem>
              <MenuItem value={1}>1 year</MenuItem>
              <MenuItem value={2}>2 years</MenuItem>
              <MenuItem value={3}>3 years</MenuItem>
              <MenuItem value={4}>4 years</MenuItem>
              <MenuItem value={5}>5 years</MenuItem>
              <MenuItem value={6}>6 years</MenuItem>
              <MenuItem value={7}>More than 6 years</MenuItem>
            </Select>
            {errors.years_of_experience && <div style={{ color: 'red' }}>{errors.years_of_experience}</div>}
          </FormControl> */}
          {/* <TextField
            label="Date of Birth"
            name="dob"
            type="date"
            variant="outlined"
            value={formData.dob}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.dob}
            helperText={errors.dob}
            InputLabelProps={{ shrink: true }}
          /> */}

          <div style={{ marginTop: '10px' }}>
            <Button variant="contained" component="label" fullWidth>
              Driver Image
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e, 'driver_image')}
                hidden
              />
            </Button>
            {imagePreviews.driver_image && (
              <>
                <img src={imagePreviews.driver_image} alt="Driver Preview" style={{ maxHeight: '200px', width: '100%', objectFit: 'contain', margin: '10px 0' }} />
                <div style={{ fontSize: 'small' }}>{formData.driver_image.name}</div>
              </>
            )}
            <div style={{ fontSize: 'small', color: 'gray' }}>
              Please upload a clear image of driver.
            </div>
            {errors.driver_image && <div style={{ color: 'red' }}>{errors.driver_image}</div>}
          </div>

          <div style={{ marginTop: '10px' }}>
            <Button variant="contained" component="label" fullWidth>
              License Image
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e, 'license_image')}
                hidden
              />
            </Button>
            {imagePreviews.license_image && (
              <>
                <img src={imagePreviews.license_image} alt="License Preview" style={{ maxHeight: '200px', width: '100%', objectFit: 'contain', margin: '10px 0' }} />
                <div style={{ fontSize: 'small' }}>{formData.license_image.name}</div>
              </>
            )}
            <div style={{ fontSize: 'small', color: 'gray' }}>
              Please upload a clear image of your driving license.
            </div>
            {errors.license_image && <div style={{ color: 'red' }}>{errors.license_image}</div>}
          </div>

          <div style={{ color: 'red', marginTop: '20px' }}>
            {Object.keys(errors).length > 0 && <p>Please fix the following errors:</p>}
            {Object.entries(errors).map(([key, error]) => (
              <div key={key}>{error}</div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{driverEditingId ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={!!flashMessage.message} autoHideDuration={6000} onClose={() => setFlashMessage({ message: '', severity: '' })}>
        <Alert onClose={() => setFlashMessage({ message: '', severity: '' })} severity={flashMessage.severity}>
          {flashMessage.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DriverForm;
