import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Pagination
} from '@mui/material';
import { api } from '../api';
import DriverForm from './components/DriverForm';

const DriverList = () => {
  const [drivers, setDrivers] = useState([]);
  const [open, setOpen] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchDrivers = async (page = 1) => {
    try {
      const response = await api.get(`/drivers?page=${page}`);
      const formattedDrivers = response.data.map(driver => ({
        ...driver,
        is_smoker: driver.is_smoker === 1,
      }));
      setDrivers(formattedDrivers);
      // Set pagination if your API returns pagination details
      setTotalPages(response.data.last_page);
      setCurrentPage(response.data.current_page);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  useEffect(() => {
    fetchDrivers(currentPage);
  }, [currentPage]);

  const handleOpen = async (driverId= null) => {
    setEditingId(driverId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingId(null);
  };

  const handleSuccess = (message) => {
    fetchDrivers(currentPage); // Refresh the driver list
    Swal.fire({
      title: 'Success!',
      text: message,
      icon: 'success',
      timer: 2700,
      showConfirmButton: false,
    });
  };

  const deleteDriver = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`/drivers/${id}`);
          fetchDrivers(currentPage);
          Swal.fire({
            title: 'Deleted!',
            text: 'The driver has been deleted.',
            icon: 'success',
            timer: 2700,
            showConfirmButton: false,
          });
        } catch (error) {
          console.error('Error deleting driver:', error);
          Swal.fire({
            title: 'Error!',
            text: 'There was an error deleting the driver. Please try again.',
            icon: 'error',
          });
        }
      }
    });
  };

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h1>Driver List</h1>
      <Button variant="contained" color="primary" onClick={() => handleOpen()} style={{ marginBottom: '20px' }}>
        Add New Driver
      </Button>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>License No</TableCell>
              <TableCell>Validity Date</TableCell>
              <TableCell>Experience (Years)</TableCell>
              <TableCell>Is Smoker</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {drivers.length > 0 ? (
              drivers.map(driver => (
                <TableRow key={driver.id}>
                  <TableCell>{driver.name}</TableCell>
                  <TableCell>{driver.phone}</TableCell>
                  <TableCell>{driver.license_no}</TableCell>
                  <TableCell>{driver.validity_date}</TableCell>
                  <TableCell>{driver.years_of_experience}</TableCell>
                  <TableCell>{driver.is_smoker ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{driver.dob}</TableCell>
                  <TableCell>{driver.status}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="secondary" onClick={() => handleOpen(driver.id)}>
                      Edit
                    </Button>
                    <Button variant="outlined" color="error" onClick={() => deleteDriver(driver.id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">No drivers available.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={(event, value) => setCurrentPage(value)}
        variant="outlined"
        shape="rounded"
        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
      />

      <DriverForm 
        open={open} 
        onClose={handleClose} 
        driverEditingId={editingId} 
        setDriverEditingId = {setEditingId}
        onSuccess={handleSuccess} 
      />
    </div>
  );
};

export default DriverList;
