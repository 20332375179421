import React, { useEffect, useState } from 'react'; 
import Swal from 'sweetalert2';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Dialog, Snackbar, Alert
} from '@mui/material';
import { api } from '../api';
import VehicleForm from './components/VehicleForm';

const VehicleList = () => {
  const [vehicles, setVehicles] = useState([]);
  const [open, setOpen] = useState(false);
  const [vehicleEditingId, setVehicleEditingId] = useState(null);
  const [flashMessage, setFlashMessage] = useState({ message: '', severity: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchVehicles(currentPage);
  }, [currentPage]);

  const fetchVehicles = async (page = 1) => {
    try {
      const response = await api.get('/vehicles', { params: { page } });
      setVehicles(response.data.data || []);
      setTotalPages(response.data.last_page);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
    }
  };

  const vehicleHandleOpen = (vehicle = null) => {
    setVehicleEditingId(vehicle ? vehicle.id : null); // Store the vehicle ID for fetching
    setOpen(true);
  };

  const vehicleHandleClose = (newVehicleId = null) => {
    fetchVehicles(); // Fetch updated vehicles
    setOpen(false);
    if (newVehicleId) {
      setVehicleEditingId(newVehicleId); // Set editing ID if a new vehicle was added
    } else {
      setVehicleEditingId(null); // Reset editing ID on close
    }
  };

  const deleteVehicle = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`/vehicles/${id}`);
          fetchVehicles(currentPage);
          Swal.fire({
            title: 'Deleted!',
            text: 'The vehicle has been deleted.',
            icon: 'success',
            timer: 2700,
            showConfirmButton: false,
          });
        } catch (error) {
          console.error('Error deleting vehicle:', error);
          Swal.fire({
            title: 'Error!',
            text: 'There was an error deleting the vehicle. Please try again.',
            icon: 'error',
          });
        }
      }
    });
  };

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h1>Vehicle List</h1>
      <Button variant="contained" color="primary" onClick={() => vehicleHandleOpen()} style={{ marginBottom: '20px' }}>
        Add New Vehicle
      </Button>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User Id</TableCell>
              <TableCell>User Name</TableCell>
              <TableCell>Registration No</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Build Year</TableCell>
              <TableCell>Available Seats</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vehicles.length > 0 ? (
              vehicles.map(vehicle => (
                <TableRow key={vehicle.id}>
                  <TableCell>{vehicle.user_id}</TableCell>
                  <TableCell>{vehicle.user_name}</TableCell>
                  <TableCell>{vehicle.registration_no}</TableCell>
                  <TableCell>{vehicle.company_name}</TableCell>
                  <TableCell>{vehicle.model_name}</TableCell>
                  <TableCell>{vehicle.build_year}</TableCell>
                  <TableCell>{vehicle.available_seats}</TableCell>
                  <TableCell>{vehicle.color}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="secondary" onClick={() => vehicleHandleOpen(vehicle)}>
                      Edit
                    </Button>
                    <Button variant="outlined" color="error" onClick={() => deleteVehicle(vehicle.id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">No vehicles available.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Controls */}
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <span style={{ margin: '0 15px' }}>Page {currentPage} of {totalPages}</span>
        <Button
          variant="contained"
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>

      <VehicleForm
        open={open}
        handleClose={vehicleHandleClose}
        vehicleId={vehicleEditingId}
        setVehicleEditingId={setVehicleEditingId} // Pass function to set editing ID
      />

      <Snackbar
        open={!!flashMessage.message}
        autoHideDuration={6000}
        onClose={() => setFlashMessage({ message: '', severity: '' })}
      >
        <Alert onClose={() => setFlashMessage({ message: '', severity: '' })} severity={flashMessage.severity}>
          {flashMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default VehicleList;
