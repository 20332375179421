import React, { useEffect, useState, useCallback, useRef } from 'react';

import { Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { Container, Row, Modal } from 'react-bootstrap';
import { styled } from '@mui/system';
import VehicleForm from './components/VehicleForm';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

import {
  Button, Dialog, DialogContent, DialogTitle, TextField, Typography,
  InputLabel, FormControl, Snackbar, Alert, Select, MenuItem, IconButton,
  RadioGroup, FormControlLabel, Radio, Tooltip, Checkbox, Autocomplete
} from '@mui/material';
import { CircularProgress, List, ListItem, ListItemText, Paper } from '@mui/material';
import { GoogleMap, Autocomplete as GoogleAutocomplete, MarkerF } from "@react-google-maps/api";

import CloseIcon from '@mui/icons-material/Close';
import { api } from '../api';

import DriverForm from './components/DriverForm';

const ScheduleForm = () => {
  const initializePoint = () => ({
    province_id: '',
    province_name: '',
    city_id: '',
    city_name: '',
    town_id: '',
    town_name: '',
    address: '',
    Bounds: {},
    marker_coordinates: {},
    address_coordinates: {},


    departureTime: '09:00',
    returnTime: '17:00',
  });

  const buttonStyles = {
    width: '170px',
    color: 'rgb(29, 104, 93)',
    borderColor: 'rgb(29, 104, 93)',
    textAlign: 'center',
    // transition: 'background-color 0.3s, color 0.3s',
  };

  // Hover functions to handle the background and text color change
  const handleMouseOver = (e) => {
    e.target.style.backgroundColor = 'rgb(29, 104, 93)';
    e.target.style.color = 'white';
  };

  const handleMouseOut = (e) => {
    e.target.style.backgroundColor = 'transparent';
    e.target.style.color = 'rgb(29, 104, 93)';
  };

  // const handleCheckboxChange = (event) => {
  //   const { value } = event.target;
  //   // Check if the day is already selected or not
  //   if (daysSelected.includes(value)) {
  //     // Day is already selected, remove it from the array
  //     setDaysSelected((prevSelectedDays) =>
  //       prevSelectedDays.filter((day) => day !== value)
  //     );
  //   } else {
  //     // Day is not selected, add it to the array
  //     setDaysSelected((prevSelectedDays) => [...prevSelectedDays, value]);
  //   }
  // };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;

    setDaysSelected((prevSelectedDays) => {
      const newDaysSelected = prevSelectedDays.includes(value)
        ? prevSelectedDays.filter((day) => day !== value)
        : [...prevSelectedDays, value];

      // Update formData with new daysSelected
      setFormData((prevFormData) => ({
        ...prevFormData,
        daysSelected: newDaysSelected,
      }));

      return newDaysSelected;
    });
  };


  const handleSeatChange = (event) => {
    setFormData({ ...formData, seats: event.target.value });
  };


  const { goingTo, carOffering, gender } = useSelector((state) => state.signup);
  const ride_type = carOffering.includes("Looking ") ? "looking" : "offering";
  const seats = ride_type === 'looking' ? 1 : 3;



  const [formData, setFormData] = useState({
    scheduleName: '',
    goingTo: goingTo || '',
    driver_id: null,
    vehicle_id: null,
    family_member_id: null,
    daysSelected: [],

    seats: seats,

    rideType: ride_type || null, // New state for the radio buttons
    points: {
      starting: initializePoint(),
      ending: initializePoint(),
      // middle_1: initializePoint(),
      // middle_2: initializePoint(),
      // middle: [],
      // Middle points will be added dynamically
    },

    // points: {
    //   starting: { province_id: '', province_name: '', city_id: '', city_name: '', town_id: '', town_name: '', address: '', departureTime: '09:00', returnTime: '17:00' },
    //   ending: { province_id: '', province_name: '', city_id: '', city_name: '', town_id: '', town_name: '', address: '', departureTime: '09:00', returnTime: '17:00' },
    //   middle: [],
    // },
  });

  const [daysSelected, setDaysSelected] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [towns, setTowns] = useState([]);
  const [flashMessage, setFlashMessage] = useState({ message: '', severity: '' });
  const [open, setOpen] = useState(false);
  const [currentPointType, setCurrentPointType] = useState('');
  const [currentMiddlePointIndex, setCurrentMiddlePointIndex] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [errors, setErrors] = useState({});
  const [lastDriver, setLastDriver] = useState({});
  const [lastVehicle, setLastVehicle] = useState({});


  // Function to get the last item in a list
  const getLastItem = (list) => list.length > 0 ? list[list.length - 1] : null;



  useEffect(() => {
    fetchFamilyMembers();
    fetchProvinces(1); // Assuming country_id = 1
    fetchDrivers();
    fetchVehicles();

  }, []);

  useEffect(() => {
    setLastDriver(getLastItem(drivers));
    setLastVehicle(getLastItem(vehicles));

  }, [drivers, vehicles]);


  const fetchDrivers = async () => {
    try {
      const response = await api.get('/drivers', { params: { dropdown: true } });
      setDrivers(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  const fetchVehicles = async () => {
    try {
      const response = await api.get('/vehicles', { params: { dropdown: true } });
      setVehicles(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
    }
  };



  const fetchFamilyMembers = async () => {
    try {
      const response = await api.get('/family-members', { params: { dropdown: true } });
      setFamilyMembers(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching family members:', error);
    }
  };

  const fetchProvinces = async (countryId) => {
    try {
      const response = await api.get('/provinces', { params: { dropdown: true, country_id: countryId } });
      setProvinces(response.data || []);
    } catch (error) {
      console.error('Error fetching provinces:', error);
    }
  };

  const fetchCities = async (provinceId) => {
    try {
      const response = await api.get('/cities', { params: { dropdown: true, province_id: provinceId } });
      setCities(response.data || []);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const fetchTowns = async (cityId) => {
    try {
      const response = await api.get('/towns', { params: { dropdown: true, city_id: cityId } });
      setTowns(response.data || []);
    } catch (error) {
      console.error('Error fetching towns:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRideTypeChange = (value) => {
    const newSeats = value === 'looking' ? 1 : 3; // Default to 1 for looking, 3 for offering

    // Update form data first
    const updatedFormData = { ...formData, rideType: value, seats: newSeats };
    setFormData(updatedFormData);
    if (Object.entries(errors).length > 0) {
      // Reset previous errors
      setErrors({});

      // Validate the updated form data
      const validationErrors = validateFormData(updatedFormData);

      // If there are any validation errors, update the errors state
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      }
    }

  };






  const handlePointChange = (point, field, value) => {
    setFormData(prev => ({
      ...prev,
      points: {
        ...prev.points,
        [point]: {
          ...prev.points[point],
          [field]: value,
        },
      },
    }));
  };

  const handleMiddlePointChange = (index, field, value) => {
    const newMiddlePoints = [...formData.points.middle];
    newMiddlePoints[index] = {
      ...newMiddlePoints[index],
      [field]: value,
    };
    setFormData({ ...formData, points: { ...formData.points, middle: newMiddlePoints } });
  };

  const addMiddlePoint = () => {
    setFormData(prev => ({
      ...prev,
      points: {
        ...prev.points,
        middle: [...prev.points.middle, { province_id: '', province_name: '', city_id: '', city_name: '', town_id: '', town_name: '', address: '', departureTime: '09:00', returnTime: '17:00' }],
      },
    }));
  };

  const validateFormData = (formData) => {

    // Create an object to hold any validation errors
    let validationErrors = {};

    // Validate based on rideType
    if (formData.rideType === 'offering') {
      if (!formData.vehicle_id) {
        validationErrors.vehicle_id = 'Plz add car info first before submission form';
      }
      if (!formData.driver_id) {
        validationErrors.driver_id = 'Plz add driver info first before submission form';
      }

    } else {
      // Additional validations for non-offering ride types (if needed)
      // For example:
      // if (!formData.someOtherField) {
      //   validationErrors.someOtherField = 'Some error message';
      // }
    }

    // Validate starting point address
    if (!formData.points.starting.town_id || !formData.points.starting.address) {
      validationErrors.starting_point = 'Plz complete the address for starting point';
    }

    // Validate ending point address
    if (!formData.points.ending.town_id || !formData.points.ending.address) {
      validationErrors.ending_point = 'Plz complete the address for ending point';
    }
    // Validate daysSelected
    if (!formData.daysSelected || formData.daysSelected.length === 0) {
      validationErrors.daysSelected = 'Plz select the days you want to commute';
    }
    // Return the validation errors object
    return validationErrors;
  };

  const handleSubmit = async () => {
    // Reset previous errors
    setErrors({});

    // Validate the form data
    const validationErrors = validateFormData(formData);

    // If there are any validation errors, update the errors state and return
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const { points, ...rest } = formData;
      const dataToPost = {
        ...rest,
        starting_point: points.starting,
        ending_point: points.ending,
        // middle_points: points.middle,
        // middle_1: points.middle_1,
        // middle_2: points.middle_2,

      };

      await api.post('/schedules', dataToPost); // POST request to the schedules API
      setFlashMessage({ message: 'Schedule created successfully!', severity: 'success' });
      clearFormData();
      setOpen(false);
    } catch (error) {
      console.error('Error creating schedule:', error);
      setFlashMessage({ message: 'Failed to create schedule.', severity: 'error' });
    }
  };

  const clearFormData = () => {
    setFormData({
      scheduleName: '',
      goingTo: goingTo || '',
      driver_id: null,
      vehicle_id: null,
      family_member_id: null,
      daysSelected: [],


      rideType: ride_type,
      points: {
        starting: initializePoint(),
        ending: initializePoint(),
        // middle_1: initializePoint(),
        // middle_2: initializePoint(),
        // middle: [],
        // Middle points will be added dynamically
      },
    });
    setCities([]);
    setTowns([]);
  };

  const openPointModal = (pointType, index) => {
    setCurrentPointType(pointType);
    setCurrentMiddlePointIndex(index);
    if (pointType === 'middle' && index !== null) {
      const selectedMiddlePoint = formData.points.middle[index];
      if (selectedMiddlePoint) {
        fetchCities(selectedMiddlePoint.province_id);
        fetchTowns(selectedMiddlePoint.city_id);
      }
    }
  };

  const closePointModal = () => {
    if (currentPointType === 'middle' && currentMiddlePointIndex !== null) {
      const newMiddlePoints = [...formData.points.middle];
      newMiddlePoints[currentMiddlePointIndex] = {
        province_id: '',
        province_name: '',
        city_id: '',
        city_name: '',
        town_id: '',
        town_name: '',
        address: '',
        Bounds: {},
        marker_coordinates: {},
        address_coordinates: {},
        departureTime: '09:00',
        returnTime: '17:00',
      };
      setFormData(prev => ({
        ...prev,
        points: { ...prev.points, middle: newMiddlePoints },
      }));
    }
    setCurrentPointType('');
    setCurrentMiddlePointIndex(null);
  };

  const handleDeletePoint = (pointType) => {
    setFormData(prev => {
      const updatedPoints = { ...prev.points };

      if (pointType === 'starting') {
        updatedPoints.starting = initializePoint();
      } else if (pointType === 'ending') {
        updatedPoints.ending = initializePoint();
      } else if (pointType === 'middle_1') {
        updatedPoints.middle_1 = initializePoint();
      } else if (pointType === 'middle_2') {
        updatedPoints.middle_2 = initializePoint();
      }

      return { ...prev, points: updatedPoints };
    });

    closePointModal();
  };

  const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputLabel-root': {
      position: 'absolute',
      left: 16,
      top: '50%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
      color: theme.palette.text.disabled,
    },
    '& input': {
      padding: '12px 16px', // Adjust padding to your liking
    },
  }));


  const [loading, setLoading] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(null);

  const [level, setLevel] = useState('province'); // 'province', 'city', or 'town'

  const handleSelectionChange = async (value) => {
    if (level === 'province') {
      const provinceName = provinces.find(prov => prov.id === value)?.name || '';
      if (currentPointType === 'middle' && currentMiddlePointIndex !== null) {
        handleMiddlePointChange(currentMiddlePointIndex, 'province_id', value);
        handleMiddlePointChange(currentMiddlePointIndex, 'province_name', provinceName);
      } else {
        handlePointChange(currentPointType, 'province_id', value);
        handlePointChange(currentPointType, 'province_name', provinceName);
      }

      setLoading(true);
      try {
        const fetchedCities = await fetchCities(value);
        setCities(Array.isArray(fetchedCities) ? fetchedCities : []);
        setLevel('city');
      } catch (error) {
        console.error("Error fetching cities:", error);
      } finally {
        setLoading(false);
      }
    } else if (level === 'city') {
      const cityName = cities.find(city => city.id === value)?.name || '';
      if (currentPointType === 'middle' && currentMiddlePointIndex !== null) {
        handleMiddlePointChange(currentMiddlePointIndex, 'city_id', value);
        handleMiddlePointChange(currentMiddlePointIndex, 'city_name', cityName);
      } else {
        handlePointChange(currentPointType, 'city_id', value);
        handlePointChange(currentPointType, 'city_name', cityName);
      }

      setLoading(true);
      try {
        const fetchedTowns = await fetchTowns(value);
        setTowns(Array.isArray(fetchedTowns) ? fetchedTowns : []);
        setLevel('town');
      } catch (error) {
        console.error("Error fetching towns:", error);
      } finally {
        setLoading(false);
      }
    } else if (level === 'town') {
      const townName = towns.find(town => town.id === value)?.name || '';
      if (currentPointType === 'middle' && currentMiddlePointIndex !== null) {
        handleMiddlePointChange(currentMiddlePointIndex, 'town_id', value);
        handleMiddlePointChange(currentMiddlePointIndex, 'town_name', townName);
      } else {
        handlePointChange(currentPointType, 'town_id', value);
        handlePointChange(currentPointType, 'town_name', townName);
      }
    }

    setCurrentSelection(value); // Update current selection
  };

  useEffect(() => {
    // Reset selection when switching levels
    setCurrentSelection(null);
    setLevel('province'); // Start from province level
  }, [provinces]);

  const [vehicleEditingId, setVehicleEditingId] = useState(null);

  const vehicleHandleOpen = () => {

    setOpen(true);
  };

  const vehicleHandleClose = (new_vehicle_id) => {
    setFormData({ ...formData, vehicle_id: new_vehicle_id })
    // fetchVehicles();
    setOpen(false);
    // setVehicleEditingId(null); // Reset editing ID on close
  };

  const [driverOpen, setDriverOpen] = useState(false);
  const [driverEditingId, setDriverEditingId] = useState(null);
  // const [drivers, setDrivers] = useState([]); // State to manage drivers list

  useEffect(() => {
    // Assuming you already have the data for drivers and vehicles (either from an API or static data)
    const lastDriver = getLastItem(drivers);
    const lastVehicle = getLastItem(vehicles);

    if (lastDriver) {
      setDriverEditingId(lastDriver.id); // Set the last driver to editing ID
    }

    if (lastVehicle) {
      setVehicleEditingId(lastVehicle.id); // Set the last vehicle to editing ID
    }
  }, [drivers, vehicles]);

  const driverHandleOpen = async (driverId = null) => {
    setDriverEditingId(driverId);
    setDriverOpen(true);
  };




  const driverHandleClose = (new_driver_id) => {
    setFormData({ ...formData, driver_id: new_driver_id })
    setDriverOpen(false);
    // setDriverEditingId(null); // Reset editing ID on close
  };



  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const [initialAddress, setinitialAddress] = useState('');




  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);
  // Debounced fetch function
  const fetchAddressSuggestions = useCallback(
    debounce(async (input, currentPointType, provinceName, cityName, townName) => {
      if (input && input.length > 2) {
        setIsLoading(true);
        try {
          // const { province_name, city_name, town_name } = formData.points[currentPointType] || {};

          // console.log('address  province_name, city_name, town_name', provinceName, cityName, townName);

          const response = await api.get('/address-suggestions', {
            params: {
              query: input,
              province_name: provinceName,
              city_name: cityName,
              town_name: townName,
            },
          });
          setSuggestions(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
          console.error('Error fetching address suggestions:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setSuggestions([]); // Clear suggestions if input length is <= 3
      }
    }, 350), // 0.35 seconds debounce
    [] // No dependencies, as this function will be recreated on each render
  );



  return (
    <>
      <div className="main-bg">
        <div className="area" >
          <ul className="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div >

        <div style={{ padding: '20px', maxWidth: '900px', margin: '0 auto' }}>


          <div
            className="row shadow  form-color-header">
            <h1 className="text-center text-white py-4">
              Registration Form
            </h1>
          </div>

          {/* <TextField
            label="Schedule Name"
            name="scheduleName"
            variant="outlined"
            value={formData.scheduleName}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          /> */}

          <FormControl fullWidth margin="normal">
            <InputLabel id="going-to-label">Going to</InputLabel>
            <Select
              labelId="going-to-label"
              name="goingTo"
              value={formData.goingTo || ''}
              onChange={handleChange}
              required
              label="Going to"
            >
              <MenuItem value="School">School</MenuItem>
              <MenuItem value="Office">Office</MenuItem>
              <MenuItem value="University">University</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>

          <RadioGroup
            row
            name="rideType"
            value={formData.rideType}
            onChange={(e) => handleRideTypeChange(e.target.value)}
          >
            <FormControlLabel value="looking" control={<Radio />} label="Looking for car" />
            <FormControlLabel value="offering" control={<Radio />} label="Offering a car" />
          </RadioGroup>


          {formData.rideType === "offering" && (
            <div>

              <div>
                <Button
                  variant="outlined"
                  style={buttonStyles}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                  onClick={() => vehicleHandleOpen()}
                // style={{ marginBottom: '20px' }}
                >
                  {vehicleEditingId ? 'Change Car Info.' : 'Add Car info.'}
                </Button>

                {lastVehicle?.name && (
                  <div>
                    <h2>Registered Car Information</h2>

                    {/* Split the car model and registration number */}
                    <p>Model: {lastVehicle.name.split(' - ')[0]}</p>
                    <p>Reg. No.: {lastVehicle.name.split(' - ')[1] || 'Not Available'}</p>

                    {/* Render other vehicle details here */}
                  </div>
                )}



                <VehicleForm
                  open={open}
                  handleClose={vehicleHandleClose}
                  vehicleId={vehicleEditingId}
                  setLastVehicle={setLastVehicle}

                  setVehicleEditingId={setVehicleEditingId} // Ensure this is correctly passed
                />

              </div>
              <div>


                <Button
                  variant="outlined"
                  // color="primary"
                  onClick={() => driverHandleOpen(driverEditingId)} // Open form for adding a new driver
                  style={buttonStyles}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                // style={{ marginBottom: '20px' }}
                >
                  {driverEditingId ? 'Edit Driver Info.' : 'Add Driver Info.'}
                </Button>

                {lastDriver?.name && (
                  <div>
                    <h2>Registered Driver Information</h2>

                    {/* Split the driver name and license */}
                    <p>Driver: {lastDriver.name.split(' - ')[0]}</p>
                    <p>License: {lastDriver.name.split(' - ')[1] || 'Not Available'}</p>

                    {/* Render other driver details here */}
                  </div>
                )}




                {errors.driver_id && (
                  <div style={{ color: 'red', marginTop: '5px' }}>{errors.driver_id}</div>
                )}


                <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>


                  <DriverForm
                    open={driverOpen}
                    onClose={driverHandleClose}
                    driverEditingId={driverEditingId}
                    setLastDriver={setLastDriver}
                    setDriverEditingId={setDriverEditingId} // Pass setter
                    setDriverOpen={setDriverOpen} // Pass setter
                  />

                </div>
              </div>


            </div>
          )}


          {/* <FormControl fullWidth margin="normal">
            <InputLabel>{formData.rideType === 'looking' ? 'Seats Required' : 'Seats Offered'}</InputLabel>
            <Select
              value={formData.seats}
              onChange={handleSeatChange}
            >
              {(formData.rideType === 'looking' ? [1, 2, 3] : Array.from({ length: 10 }, (_, i) => i + 1)).map(option => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl> */}

          {/* <FormControl fullWidth margin="normal">
            <InputLabel>Choose Driver</InputLabel>
            <Select
              name="driver_id"
              value={formData.driver_id || ''}
              onChange={(e) => setFormData({ ...formData, driver_id: e.target.value })}
            >
              {drivers.map(driver => (
                <MenuItem key={driver.id} value={driver.id}>{driver.name}</MenuItem>
              ))}
            </Select>

          </FormControl> */}
          {/* 
          <FormControl fullWidth margin="normal">
            <InputLabel>Choose Vehicle</InputLabel>
            <Select
              name="vehicle_id"
              value={formData.vehicle_id || ''}
              onChange={(e) => setFormData({ ...formData, vehicle_id: e.target.value })}
            >
              {vehicles.map(vehicle => (
                <MenuItem key={vehicle.id} value={vehicle.id}>{vehicle.name}</MenuItem>
              ))}
            </Select>
          </FormControl> */}









          {/* <FormControl fullWidth margin="normal">
            <InputLabel>Schedule for</InputLabel>
            <Select
              name="family_member_id"
              value={formData.family_member_id === null ? 'self' : formData.family_member_id || ''}
              onChange={(e) => {
                const value = e.target.value === 'self' ? null : e.target.value;
                setFormData({ ...formData, family_member_id: value });
              }}
            >
              <MenuItem value='self'>My Self</MenuItem>
              {familyMembers.map(member => (
                <MenuItem key={member.id} value={member.id}>{member.name}</MenuItem>
              ))}
            </Select>
          </FormControl> */}

          <h2 className="text-success mb-3 text-center">
            STARTING POINT
            <Tooltip title={<h6 className="px-2">{"You have the option to choose your starting or pickup location using Google Map, which becomes accessible once you have selected your province, city and area."}</h6>}>
              <Link
                // to='/notification'
                className='mx-1 h-15px d-inline-block'
                style={{ cursor: "pointer" }}
              >
                <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
              </Link>
            </Tooltip>
          </h2>
          {!formData.points.starting.province_id && (
            <Button onClick={() => openPointModal('starting', null)} variant="outlined" style={buttonStyles}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}>
              Add Starting Point
            </Button>
          )}

          {formData.points.starting.province_id && (
            <div style={{ margin: '10px 0' }}>
              {formData.points.starting.province_name}, {formData.points.starting.city_name}, {formData.points.starting.town_name}
              <br />
              {formData.points.starting.address} {/* - {formData.points.starting.departureTime} - {formData.points.starting.returnTime} */}
              <Button onClick={() => handleDeletePoint('starting')} style={{ float: 'right', color: 'red' }}>Delete address</Button>
            </div>
          )}
          <h2 className="text-success mb-3 text-center">
            DROP-OFF POINT
            <Tooltip title={<h6 className="px-2">{"You have the option to choose your drop-off location using Google Map, which becomes accessible once you've selected your province, city and area."}</h6>}>
              <Link
                // to='/notification'
                className='mx-1 h-15px d-inline-block'
                style={{ cursor: "pointer" }}
              >
                <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
              </Link>
            </Tooltip>
          </h2>

          {!formData.points.ending.province_id && (
            <Button onClick={() => openPointModal('ending', null)} variant="outlined" style={buttonStyles}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}>
              Add Ending Point
            </Button>
          )}
          {formData.points.ending.province_id && (
            <div style={{ margin: '10px 0' }}>
              {formData.points.ending.province_name}, {formData.points.ending.city_name}, {formData.points.ending.town_name}
              <Button onClick={() => handleDeletePoint('ending')} style={{ float: 'right', color: 'red' }}>Delete address</Button>
              <br />
              {formData.points.ending.address} {/* - {formData.points.ending.departureTime} - {formData.points.ending.returnTime} */}
            </div>
          )}


          <Row className="my-3 form-color-field">
            <Form.Group as={Col} md="12" controlId="validationCustom10">
              <Form.Label className="pt-3 px-3 text-black">
                I Commute (Select Days)
              </Form.Label>
            </Form.Group>

            <div className="row d-flex px-4">
              <div className="col d-flex flex-wrap">
                {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
                  <div key={day} className="me-3">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="group1"
                          value={day}
                          color="success"
                          checked={daysSelected.includes(day)}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={day}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Row>


          <Dialog open={currentPointType !== ''} onClose={closePointModal} PaperProps={{
            style: { width: '500px', height: '500px', maxWidth: 'none' }, // Fixed width with no max width
          }}
          >
            {(() => {
              // Define dynamicPointType outside the JSX
              const dynamicPointType = currentPointType.charAt(0).toUpperCase() + currentPointType.slice(1).toLowerCase();

              const provinceName = formData.points[currentPointType]?.province_name || '';
              const cityName = formData.points[currentPointType]?.city_name || '';
              const townName = formData.points[currentPointType]?.town_name || '';

              const handleMapsClick = async (e) => {
                console.log('handleMapClickStart event', e.placeId);
                // console.log('handleMapClickStart event', JSON.stringify(e, null, 2));
                const lat = e.latLng.lat();
                const lng = e.latLng.lng();
                const placeId = e.placeId;


                // setMarkerPositionStart({ lat, lng, placeId });
                handlePointChange(currentPointType, 'marker_coordinates', { lat, lng });

                // Call fetchPlaceInfo here
                await fetchPlaceInfo(lat, lng, placeId);
              };


              const fetchPlaceInfo = async (lat, lng, placeId = null) => {
                const geocoder = new window.google.maps.Geocoder();

                let request = {};

                if (placeId) {
                  // If a placeId is provided, request place information by placeId
                  request = { placeId: placeId };
                } else {
                  // If no placeId, use lat/lng for reverse geocoding
                  request = { location: { lat, lng } };
                }

                geocoder.geocode(request, (results, status) => {
                  if (status === 'OK' && results[0]) {
                    // console.log('Geocoding results:', JSON.stringify(results, null, 2));

                    // Set the formatted address (or any other place info)
                    // setLocationStartStringField(results[0].formatted_address);
                    const formatted_address = results[0].formatted_address;
                    console.log('results[0].formatted_address', formatted_address);
                    handlePointChange(currentPointType, 'address', formatted_address);

                    // Extract the place information
                    // setPlaceInfo(results[0]);

                    // // Show the info window (optional)
                    // setShowInfoWindow(true);
                  } else {
                    console.error('Geocoding failed due to:', status);
                  }
                });
              };


              // Function to fetch the geocoding data
              const getAddressSuggestionsData = async (currentPointType, address) => {
                setinitialAddress(address);

                handlePointChange(currentPointType, 'address', address);


                // Query the .pac-container (Google Autocomplete's suggestion dropdown)
                const pacContainer = document.querySelector('.pac-container');
                console.log('pacContainer on change:', pacContainer); // Debugging log
                // Apply styles only if .pac-container exists
                // Only try to query pac-container if inputRef.current is not null
                const pacContainers = document.querySelectorAll('.pac-container');

                // If there are any pac-containers, apply the styles to the last one
                if (pacContainers.length > 0) {
                  const lastPacContainer = pacContainers[pacContainers.length - 1];

                  // Remove `display: none` and `z-index` properties if they exist
                  lastPacContainer.style.display = 'block'; // Ensure it is visible
                  lastPacContainer.style.zIndex = '9999'; // Remove any existing z-index

                  // Apply custom styles
                  lastPacContainer.style.position = 'fixed';
                  // lastPacContainer.style.bottom = '100px'; // Adjust this based on your layout
                  // lastPacContainer.style.top = 'auto'; // Ensure it doesn't get pushed off the screen
                  // lastPacContainer.style.left = '0'; // Position it to the left (or adjust based on your layout)
                }
                console.log('pacContainer on change: after', pacContainer);
                try {
                  if (address) {
                    console.log('getAddressSuggestionsData 12s', address);
                    const response = await fetch(
                      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyCrX4s2Y_jbtM-YZOmUwWK9m-WvlCu7EXA`
                    );

                    const data = await response.json(); // Parse the response as JSON
                    console.log('getAddressSuggestionsData data', data);

                    if (data.status === "OK" && data.results.length > 0) {
                      const { lat, lng } = data.results[0].geometry.location;

                      console.log('getAddressSuggestionsData lat, lng', lat, lng);

                      // setDefaultStartCenter({ lat, lng });
                    } else {
                      console.error("Geocoding API response error");
                    }
                  }
                } catch (error) {
                  console.error("Error fetching geocoding data:", error);
                }
              };



              const fetchLocationBounds = async (currentPointType, locationName) => {
                try {
                  const response = await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${locationName}&key=AIzaSyCrX4s2Y_jbtM-YZOmUwWK9m-WvlCu7EXA`
                  );

                  if (response.ok) {
                    const data = await response.json();
                    if (data.results.length > 0) {
                      const tmp_data = data.results[0].geometry.bounds;

                      const locationBounds = {
                        south: tmp_data.southwest.lat,
                        west: tmp_data.southwest.lng,
                        north: tmp_data.northeast.lat,
                        east: tmp_data.northeast.lng,
                      };





                      // Set the bounds using the provided setter function
                      // setBounds(locationBounds);
                      handlePointChange(currentPointType, 'Bounds', locationBounds);

                    } else {
                      console.error("Location not found");
                    }
                  } else {
                    console.error("Error fetching location data");
                  }
                } catch (error) {
                  console.error("An error occurred:", error);
                }
              };


              const handlePlaceSelect = async () => {
                const place = autocompleteRef.current.getPlace();
                console.log('handlePlaceSelectStart called place', place);
                console.log('handlePlaceSelectStart cityName', provinceName);




                // Check if place is defined and has address_components.
                if (place && place.address_components) {
                  const isCity = place.address_components.some(
                    (component) =>
                      component.types.includes("locality") &&
                      component.long_name.toLowerCase() === provinceName.toLowerCase()
                  );

                  // Check if the place's address contains the city name in its formatted address.
                  const containsCityName = place.formatted_address
                    .toLowerCase()
                    .includes(provinceName.toLowerCase());

                  if (isCity || containsCityName) {
                    if (place.geometry && place.geometry.location) {
                      // setMarkerPositionStart({
                      //     lat: place.geometry.location.lat(),
                      //     lng: place.geometry.location.lng(),
                      // });
                      const lat = place.geometry.location.lat();
                      const lng = place.geometry.location.lng();
                      const address = place.name + ', ' + place.formatted_address;


                      // handlePointChange(currentPointType, 'lat', lat);
                      // handlePointChange(currentPointType, 'lng', lng);
                      handlePointChange(currentPointType, 'address_coordinates', { lat, lng });
                      handlePointChange(currentPointType, 'address', address);
                      console.log('lat, lng, address', lat, lng, address)

                      // setLocationStartStringField(place.name + ', ' + place.formatted_address);
                      // setLocationStartString(place.formatted_address);
                      // handleShowStartModal();
                    }
                  } else {
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    const address = place.name + ', ' + place.formatted_address;


                    // handlePointChange(currentPointType, 'lat', lat);
                    // handlePointChange(currentPointType, 'lng', lng);
                    handlePointChange(currentPointType, 'address_coordinates', { lat, lng });
                    handlePointChange(currentPointType, 'address', address);
                    console.log('else -- lat, lng, address', lat, lng, address)
                    // displayNotification("warning", `Please select a place in ${cityName}`);
                  }
                } else {
                  // Handle the case when place is not valid.
                  console.error("Invalid place object:", place);
                }
              };




              const handleAddressChange = (e) => {
                const input = e.target.value;

                // Call the debounced fetch function
                fetchAddressSuggestions(input, currentPointType, provinceName, cityName, townName);

                // Immediately update the address in the parent component
                handlePointChange(currentPointType, 'address', input);
              };

              return (
                <>
                  <DialogTitle>
                    {dynamicPointType} Point Address
                    <IconButton style={{ position: 'absolute', right: 8, top: 8 }} onClick={closePointModal}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <div style={{ margin: '10px 0' }}>
                      {provinceName && `${provinceName}`}
                      {cityName && `, ${cityName}`}
                      {townName && `, ${townName}`}
                      <br />
                      {formData.points[currentPointType]?.address && formData.points[currentPointType].address}

                      <div>
                        <h3>Bounds</h3>

                        <pre>{JSON.stringify(formData.points[currentPointType]?.Bounds, null, 2)}</pre>

                        <h3>cordinates</h3>

                        <pre>{JSON.stringify(formData.points[currentPointType]?.address_coordinates, null, 2)}</pre>



                      </div>

                    </div>


                    {/* <FormControl fullWidth margin="normal">
                      <InputLabel>{level.charAt(0).toUpperCase() + level.slice(1)}</InputLabel>
                      <Select
                        value={currentSelection || ''}
                        onChange={(e) => handleSelectionChange(e.target.value)}
                        disabled={loading} // Disable while loading
                      >
                        {loading ? (
                          <MenuItem disabled>
                            <CircularProgress size={24} />
                          </MenuItem>
                        ) : level === 'province' ? (
                          provinces.map(province => (
                            <MenuItem key={province.id} value={province.id}>{province.name}</MenuItem>
                          ))
                        ) : level === 'city' ? (
                          cities.map(city => (
                            <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>
                          ))
                        ) : level === 'town' ? (
                          towns.map(town => (
                            <MenuItem key={town.id} value={town.id}>{town.name}</MenuItem>
                          ))
                        ) : null}
                      </Select>
                    </FormControl> */}






                    {!provinceName && (
                      <>

                        <FormControl fullWidth margin="normal">
                          <Autocomplete
                            options={provinces}
                            getOptionLabel={(option) => option.name}
                            value={provinces.find(prov => prov.id === formData.points[currentPointType]?.province_id) || null}
                            onChange={(event, newValue) => {
                              const provinceId = newValue ? newValue.id : '';
                              const provinceName = newValue ? newValue.name : '';

                              fetchCities(provinceId);
                              handlePointChange(currentPointType, 'province_id', provinceId);
                              handlePointChange(currentPointType, 'province_name', provinceName);
                              // if (provinceName) {
                              //   fetchLocationBounds(currentPointType, provinceName);
                              // }


                            }}
                            open={true}
                            renderInput={(params) => (
                              <StyledTextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Address"
                                InputLabelProps={{
                                  shrink: false, // Prevent the label from floating
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </>
                    )}

                    {!cityName && provinceName && (
                      <>
                        <FormControl fullWidth margin="normal">
                          <Autocomplete
                            options={cities}
                            getOptionLabel={(option) => option.name}
                            value={cities.find(city => city.id === formData.points[currentPointType]?.city_id) || null}
                            onChange={(event, newValue) => {
                              const cityId = newValue ? newValue.id : '';
                              const cityName = newValue ? newValue.name : '';

                              fetchTowns(cityId);
                              handlePointChange(currentPointType, 'city_id', cityId);
                              handlePointChange(currentPointType, 'city_name', cityName);

                              if (cityName) {
                                const fullLocationName = `${cityName}, ${provinceName}`;
                                fetchLocationBounds(currentPointType, fullLocationName);
                              }

                            }}
                            open={true}
                            renderInput={(params) => (
                              <StyledTextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Address"
                                InputLabelProps={{
                                  shrink: false, // Prevent the label from floating
                                }}
                              />
                            )}
                          />
                        </FormControl>

                      </>
                    )}



                    {!townName && cityName && (
                      <>
                        <FormControl fullWidth margin="normal">
                          <Autocomplete
                            options={towns}
                            getOptionLabel={(option) => option.name}
                            value={towns.find(town => town.id === formData.points[currentPointType]?.town_id) || null}
                            onChange={(event, newValue) => {
                              const townId = newValue ? newValue.id : '';
                              const townName = newValue ? newValue.name : '';

                              handlePointChange(currentPointType, 'town_id', townId);
                              handlePointChange(currentPointType, 'town_name', townName);
                              // if (townName) {
                              //   const fullLocationName = `${townName}, ${cityName}, ${provinceName}`;
                              //   fetchLocationBounds(currentPointType, fullLocationName);
                              // }

                            }}
                            open={true}
                            renderInput={(params) => (
                              <StyledTextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Address"
                                InputLabelProps={{
                                  shrink: false, // Prevent the label from floating
                                }}
                              />
                            )}
                          />
                        </FormControl>

                      </>
                    )}




                    {/* <TextField
                      label="Street Address / House Number"
                      variant="outlined"
                      value={
                        currentPointType === 'middle' && currentMiddlePointIndex !== null
                          ? formData.points.middle[currentMiddlePointIndex]?.address || ''
                          : formData.points[currentPointType]?.address || ''
                      }
                      onChange={(e) => {
                        const address = e.target.value;
                        if (currentPointType === 'middle' && currentMiddlePointIndex !== null) {
                          handleMiddlePointChange(currentMiddlePointIndex, 'address', address);
                        } else {
                          handlePointChange(currentPointType, 'address', address);
                        }
                      }}
                      fullWidth
                      margin="normal"
                    /> */}

                    {townName && (
                      <>

                        {/* <Autocomplete
                          options={isLoading ? suggestions : suggestions}
                          loading={isLoading}
                          noOptionsText="Type address"
                          onInputChange={handleAddressChange}
                          onChange={(event, newValue) => {
                            handlePointChange(currentPointType, 'address', newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Street Address / House Number"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              defaultValue={initialAddress}
                              // required // Make this field required
                              error={!initialAddress && !params.inputProps.value} // Optional: Manage error state
                              helperText={!initialAddress && !params.inputProps.value ? "Street Address is required." : ""}
                            />
                          )}
                        /> */}




                        <>
                          {/* <Form.Group as={Col} md="12" controlId="validationCustom05"> */}
                          {/* <Form.Label className="text-black">Street Address / House Number</Form.Label> */}
                          {/* <GoogleAutocomplete
                                                    onLoad={(autocomplete) =>
                                                      (autocompleteRef.current = autocomplete)
                                                    }
                              onPlaceChanged={handlePlaceSelect}
                              restrictions={{ country: "PK" }}
                              bounds={formData.points[currentPointType]?.Bounds}
                              options={{ strictBounds: true }}
                            >
                              <StyledTextField
                                variant="outlined"
                                required
                                autoComplete="on"
                                InputLabelProps={{
                                  shrink: false, // Prevent the label from floating
                                }}
                                className="text-dark mt-1"
                                fullWidth
                                margin="normal"
                                defaultValue={initialAddress}
                                value={formData.points[currentPointType]?.address}
                                onChange={(event) => {
                                  getAddressSuggestionsData(currentPointType, event.target.value);
                               
                                }}
                                // error={!initialAddress && !formData.points[currentPointType]?.address} // Manage error state
                                // helperText={!initialAddress && !formData.points[currentPointType]?.address ? "Street Address is required." : ""}
                              />
                            </GoogleAutocomplete> */}


                          <>
                            {/* <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom05"
                        > */}
                            <Form.Label className="text-black">Area</Form.Label>
                            <GoogleAutocomplete
                              onLoad={(autocomplete) =>
                                (autocompleteRef.current = autocomplete)
                              }
                              onPlaceChanged={handlePlaceSelect}
                              restrictions={{ country: "PK" }}
                              bounds={formData.points[currentPointType]?.Bounds}
                              options={{ strictBounds: true }}
                            >
                              <Form.Control
                                autoComplete="on"
                                required
                                type="text"
                                value={formData.points[currentPointType]?.address}
                                onChange={(event) => {
                                  getAddressSuggestionsData(currentPointType, event.target.value);

                                }}
                                className="text-dark mt-1"
                                placeholder="Enter your area"
                                autocomplete="on"
                                defaultValue=""
                              />
                            </GoogleAutocomplete>
                            {/* </Form.Group> */}

                          </>
                        </>


                        {/* 
                        <TextField
                          label="Departure Time"
                          type="time"
                          value={
                            currentPointType === 'middle' && currentMiddlePointIndex !== null
                              ? formData.points.middle[currentMiddlePointIndex]?.departureTime || ''
                              : formData.points[currentPointType]?.departureTime || ''
                          }
                          onChange={(e) => {
                            const time = e.target.value;
                            if (currentPointType === 'middle' && currentMiddlePointIndex !== null) {
                              handleMiddlePointChange(currentMiddlePointIndex, 'departureTime', time);
                            } else {
                              handlePointChange(currentPointType, 'departureTime', time);
                            }
                          }}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          label="Return Time"
                          type="time"
                          value={
                            currentPointType === 'middle' && currentMiddlePointIndex !== null
                              ? formData.points.middle[currentMiddlePointIndex]?.returnTime || ''
                              : formData.points[currentPointType]?.returnTime || ''
                          }
                          onChange={(e) => {
                            const time = e.target.value;
                            if (currentPointType === 'middle' && currentMiddlePointIndex !== null) {
                              handleMiddlePointChange(currentMiddlePointIndex, 'returnTime', time);
                            } else {
                              handlePointChange(currentPointType, 'returnTime', time);
                            }
                          }}
                          fullWidth
                          margin="normal"
                        /> */}

                      </>
                    )}


                    {townName && (
                      <>
                        <br />


                        <div className="text-danger fs-7">
                          <div className="h6">
                            To get maximum suggestions/matches, please select a prominent landmark or community/society gate as a {currentPointType === 'starting' ? 'pickup' : 'drop-off'} point.
                          </div>
                          <div className="h6">
                            If you prefer not to provide your exact location, choose your nearest landmark instead.
                          </div>

                        </div>


                        <Row style={{ height: "100%", width: "100%" }}>
                          <GoogleMap
                            zoom={20}
                            center={formData.points[currentPointType]?.address_coordinates}
                            mapContainerStyle={{
                              width: "100%",
                              height: "50vh",
                            }}
                            onClick={handleMapsClick}
                            options={{
                              types: ["(regions)"],
                              componentRestrictions: { country: "PK" },
                            }}
                          >
                            <MarkerF

                              position={formData.points[currentPointType]?.marker_coordinates}
                              icon={{
                                url: `https://maps.google.com/mapfiles/ms/icons/${currentPointType === 'starting' ? 'blue' : 'red'}-dot.png`,
                              }}
                            />
                          </GoogleMap>
                        </Row>

                      </>

                    )}





                    <Button variant="contained" color="primary" onClick={closePointModal} style={{ float: 'right', marginTop: '20px' }}>
                      Save Point
                    </Button>
                  </DialogContent>
                </>
              );
            })()}
          </Dialog>


          <Button variant="contained" className="btn-custom1 mx-2 border-0 px-4 py-2 rounded rounded-2 text-white fw-bold" onClick={handleSubmit} style={{ float: 'right', marginTop: '20px' }}>
            Submit
          </Button>

          <div
            style={{
              height: 'calc(5 * 1.5em)', // Fixed height for 5 lines of text
              lineHeight: '1.5', // Adjust as necessary for your font
              overflow: 'hidden',
              visibility: 'hidden', // Makes it not take up space
            }}
          >
            {/* No text content here */}
          </div>

          <div
            style={{
              width: '100%', // Full width of the screen
              borderBottom: '1px dotted black', // Dotted line style
              margin: '0', // Optional: reset margin if needed
            }}
          />
          <div
            style={{
              height: 'calc(2 * 1.5em)', // Fixed height for 5 lines of text
              lineHeight: '1.5', // Adjust as necessary for your font
              overflow: 'hidden',
              visibility: 'hidden', // Makes it not take up space
            }}
          >
            {/* No text content here */}
          </div>

          <div >
            <h2>Signup Details</h2>
            <p><strong>Going To:</strong> {goingTo || "Not specified"}</p>
            <p><strong>Type:</strong> {carOffering || "Not specified"}</p>
            <p><strong>Gender:</strong> {gender || "Not specified"}</p>
            <p><strong>Current Type:</strong> {formData.rideType || "Not specified"}</p>
          </div>







          {Object.entries(errors).length > 0 && (
            <div style={{ color: 'red', marginTop: '10px' }}>
              <Typography variant="body2" gutterBottom>
                Please fix the following errors:
              </Typography>
              {Object.entries(errors).map(([key, message]) => (
                <div key={key}>{message}</div>
              ))}
            </div>
          )}


          <Snackbar
            open={!!flashMessage.message}
            autoHideDuration={6000}
            onClose={() => setFlashMessage({ message: '', severity: '' })}
          >
            <Alert onClose={() => setFlashMessage({ message: '', severity: '' })} severity={flashMessage.severity}>
              {flashMessage.message}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </>
  );
};

export default ScheduleForm;
