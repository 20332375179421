import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select,
    InputLabel, FormControl, Button, Snackbar, Alert, FormHelperText
} from '@mui/material';
import { api } from '../../api';

const VehicleForm = ({ open, handleClose, vehicleId, setVehicleEditingId, setLastVehicle }) => {
    const initialFormData = {
        registration_no: '',
        company_id: '',
        model_id: '',
        build_year: 2010,
        available_seats: '',
        image: null,
        color: '',
    };

    const [formData, setFormData] = useState(initialFormData);
    const [companies, setCompanies] = useState([]);
    const [models, setModels] = useState([]);
    const [errors, setErrors] = useState({});
    const [flashMessage, setFlashMessage] = useState({ message: '', severity: '' });
    const [imageSelected, setImageSelected] = useState(false);
    const [imagePreview, setImagePreview] = useState('');
    const [seatingCapacity, setSeatingCapacity] = useState('');

    const vehicleColors = ['Select Color', 'White', 'Black', 'Silver', 'Red', 'Green', 'Blue', 'Yellow', 'Cyan', 'Magenta', 'Orange', 'Purple'];

    useEffect(() => {
        fetchCompanies();
        if (vehicleId) {
            //   fetchVehicle(vehicleId);
            setFormData(initialFormData);
        } else {
            setFormData(initialFormData);
        }
    }, [vehicleId, open]);

    const fetchCompanies = async () => {
        try {
            const response = await api.get('/companies');
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    const fetchVehicle = async (id) => {
        try {
            const response = await api.get(`/vehicles/${id}`);
            setFormData({
                ...response.data,
                image: null,
            });
            fetchModels(response.data.company_id);
        } catch (error) {
            console.error('Error fetching vehicle:', error);
        }
    };

    const fetchModels = async (companyId) => {
        try {
            const response = await api.get('/models', { params: { company_id: companyId, dropdown: 'true' } });
            setModels(response.data);
        } catch (error) {
            console.error('Error fetching models:', error);
        }
    };

    const handleCompanyChange = (event) => {
        const selectedCompanyId = event.target.value;
        setFormData({ ...formData, company_id: selectedCompanyId, model_id: '' });
        fetchModels(selectedCompanyId);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({ ...formData, image: file });
            setImageSelected(true);
            // Create a preview URL for the image
            const previewUrl = URL.createObjectURL(file);
            setImagePreview(previewUrl);
        } else {
            setImageSelected(false);
            setImagePreview('');
        }
    };

    const validateForm = () => {
        const { registration_no, company_id, model_id, available_seats } = formData;
        let formErrors = {};
        if (!registration_no) formErrors.registration_no = 'Registration number is required';
        if (!company_id) formErrors.company_id = 'Company is required';
        if (!model_id) formErrors.model_id = 'Model is required';
        if (available_seats < 1) formErrors.available_seats = 'Available seats must be at least 1';

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        const formDataToSubmit = new FormData();
        for (const key in formData) {
            formDataToSubmit.append(key, formData[key]);
        }

        try {
            if (vehicleId) {
                const response = await api.put(`/vehicles/${vehicleId}`, formDataToSubmit);
                setFlashMessage({ message: 'Vehicle updated successfully!', severity: 'success' });
                const newVehicleId = response.data.id; // Get the ID from the response
                setVehicleEditingId(newVehicleId); // Set the editing ID here
                handleClose(newVehicleId); // Pass the new vehicle ID to close function

            } else {
                const response = await api.post('/vehicles', formDataToSubmit);
                setFlashMessage({ message: 'Vehicle added successfully!', severity: 'success' });
                const newVehicle = response.data; // Get the ID from the response
                setVehicleEditingId(newVehicle.id); // Set the editing ID here
                setLastVehicle({
                    'id': newVehicle.id,
                    'name': newVehicle.name
                });
                handleClose(newVehicle.id); // Pass the new vehicle ID to close function
                setImageSelected(false);


            }



            //   handleClose(); // Close form after submission
        } catch (error) {
            console.error('Error saving vehicle:', error);
            setFlashMessage({ message: 'Error saving vehicle. Please try again.', severity: 'error' });
            // Do not close the modal on error
        }
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{vehicleId ? 'Edit Car Information' : 'Add Car Information'}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Company</InputLabel>
                        <Select
                            name="company_id"
                            value={formData.company_id}
                            onChange={handleCompanyChange}
                            required
                            label = "Company"
                        >
                            {companies.map(company => (
                                <MenuItem key={company.id} value={company.id}>
                                    {company.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.company_id && <div style={{ color: 'red', marginTop: '4px' }}>{errors.company_id}</div>}
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <InputLabel>Model</InputLabel>
                        <Select
                            name="model_id"
                            value={formData.model_id}
                            onChange={(e) => {
                                const selectedModelId = e.target.value;
                                handleChange(e); // Call your existing handleChange function
                                // Find the selected model and set its seating capacity
                                const selectedModel = models.find(model => model.id === selectedModelId);
                                if (selectedModel) {
                                    setSeatingCapacity(selectedModel.seating_capacity);
                                }
                            }}
                            required
                            label = "Model"
                        >
                            {models.map(model => (
                                <MenuItem key={model.id} value={model.id}>
                                    {model.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.model_id && <div style={{ color: 'red', marginTop: '4px' }}>{errors.model_id}</div>}
                    </FormControl>

                    <TextField
                        label="Registration No"
                        name="registration_no"
                        variant="outlined"
                        value={formData.registration_no}
                        onChange={handleChange}
                        required
                        fullWidth
                        margin="normal"
                        error={!!errors.registration_no}
                        helperText={errors.registration_no}
                    />

                    <FormControl fullWidth margin="normal">
                        <InputLabel>Build Year</InputLabel>
                        <Select
                            name="build_year"
                            value={formData.build_year}
                            onChange={handleChange}
                            required
                            label = "Build Year"
                        >
                            {Array.from({ length: 45 }, (v, i) => 1980 + i).map(year => (
                                <MenuItem key={year} value={year}>{year}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <InputLabel>Available Seats</InputLabel>
                        <Select
                            name="available_seats"
                            value={formData.available_seats}
                            onChange={handleChange}
                            required
                            label = "Available Seats"
                        >
                            {Array.from({ length: seatingCapacity > 4 ? seatingCapacity - 2 : seatingCapacity - 1 }, (v, i) => i + 1).map(seat => (
                                <MenuItem key={seat} value={seat}>{seat}</MenuItem>
                            ))}
                        </Select>
                        {errors.available_seats && <div style={{ color: 'red', marginTop: '4px' }}>{errors.available_seats}</div>}
                    </FormControl>

                    <div>
                        {imageSelected && formData.image && (
                            <div style={{ marginTop: '10px' }}>

                                <img
                                    src={imagePreview}
                                    alt="Preview"
                                    style={{ width: 'auto', height: 'auto', maxHeight: '500px', maxWidth: '500px', marginTop: '10px' }}
                                />
                                <FormHelperText>
                                    Image selected: {formData.image.name}
                                </FormHelperText>
                            </div>
                        )}


                        <Button
                            variant="contained"
                            component="label"
                            margin="normal"
                            style={{ height: '40px' }}
                        >
                            {imageSelected ? 'Change Image' : 'Upload Image'}
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                        </Button>
                        <FormHelperText>
                            Upload the front side of your car image with a visible number plate:
                        </FormHelperText>

                    </div>

                    <FormControl fullWidth margin="normal">
                        <InputLabel>Color</InputLabel>
                        <Select
                            name="color"
                            value={formData.color}
                            onChange={handleChange}
                            label = "Color"
                        >
                            {vehicleColors.map((color, index) => (
                                <MenuItem key={index} value={color}>
                                    {color}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose} color="secondary">Cancel</Button> */}
                    <Button onClick={handleSubmit} color="primary">Save</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={!!flashMessage.message} autoHideDuration={6000} onClose={() => setFlashMessage({ message: '', severity: '' })}>
                <Alert onClose={() => setFlashMessage({ message: '', severity: '' })} severity={flashMessage.severity}>
                    {flashMessage.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default VehicleForm;
